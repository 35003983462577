import { Box } from '@mui/material'
import React from 'react'

const Banner = () => {
  return (
      <Box sx={{position: 'absolute', 
        top:'0', left:'0', 
        //height:'100%',
        height:'15vh',

         width: '100%', 
        backgroundColor:'rgba( 0, 0, 0, 0.5)',
        textAlign:'center',
        justifyContent:'center',
        // alignItems:'center',
        alignContent:'center',
        boxShadow:'inset 0px 0px 10px 4px rgba( 0, 0, 0, 0.5)'
        }}>
       <h2>Banner Space</h2>
    </Box>
  )
}

export default Banner