import React, {useContext,useEffect, useState, useRef} from 'react'
// const Def = require('./default')
import DeleteIcon from '@mui/icons-material/Delete';
import LanguageIcon from '@mui/icons-material/Language';
import SwipeableDrawerComp from '../components/SwipeableDrawer';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CategoryChip from '../components/CategoryChip';
import AddComment from '../components/AddComment';
import CommentCard from '../components/CommentCard';
import { ArrowDropDown, ArrowDropUp, RadioButtonChecked } from '@mui/icons-material';
import { AppContext } from "../context/AppProvider";
import * as API from '../utils/APICalls'
import * as helpers from '../utils/helpers'
import {useParams} from'react-router-dom'
import LogoCopy from '../components/LogoCopy';
import MapComponent from '../components/MapComponent';
import useWindowDimensions from "../hooks/windowDimensions";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar,  Controller,FreeMode, Thumbs } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';


const { Card, Typography, Box, Stack, Rating, Container, Paper, Button, IconButton, Link, List, ListItem } = require('@mui/material')

const showWidth =  '300px'
let score = 0

  const contStyles ={
    position:'absolute',
    top:'15vh',
    left:'50%',
    transform:'translate(-50%,0)',
    fontFamily: 'Poppins,Helvetica Neue,Helvetica,Arial,sans-serif',
    padding:'0.5rem'
    // margin:'auto'
  }


const goalCardStyles ={
    display:'flex',
    flexDirection:'column',
    border:'1px solid #858585',
    borderRadius:'10px',
    boxShadow:'0px 0px 3px 2 px rgba( 0, 0, 0,0.4)',
    padding:'0.5rem',
    flex:1
    // width:'50%'
}

function Show (props){
  const { view } = props
  const [ controlledSwiper, setControlledSwiper ] = useState(null);
  const { height, width } = useWindowDimensions();
  const revRef = useRef(null)
  const { currentPlace, setCurrentPlace, setShowSearch,updateAppData, appData} = useContext(AppContext)
  const [ showAddComment,setShowAddComment ] = useState(true)
  const [ showHelper, setShowHelper ] = useState(true)
  const [ showHours, setShowHours ] = useState(false)
  const [ loading, setLoading ] = useState(true);
  const [ thumbsSwiper, setThumbsSwiper ] = useState(null);
  const id = useParams().id?.toString()
  let  p = currentPlace? currentPlace :null
  const images = helpers.compileImages(p)

  let comments = (
    <h3 className = "inactive">
      No comments yet!
    </h3>
  )
  let rating = (
    <h3 className="inactive">
      Not yet rated (Be the first)!
    </h3>
  )
  // console.log('location:',p?.location?.city)

  if(p?.comments?.length){
    score = helpers?.ratingScore(p?.comments)
  }
  // console.log('p?.ratingScore:',score)
  
  // let placeId = Number(data.id)
  // console.log(`Place comments`,p)
  let stars = ''

  if (p?.comments?.length){

    comments = p?.comments?.reverse().map((c,i )=>{
      // const [showReply, setShowReply ] = useState(false)
      return(<CommentCard index={i} key={i}  comment={c} place={p}  />
      //  <Stack sx={{alignItems:'center', gap:'0.5rem'}}>
      //      <Box sx={{width:'300px',alignSelf:'end'}}>
      //        <AddComment view='reply' prevCom={c}/>
      //     </Box>
      //   </Stack> 
      )
    })
  }

  // console.log('images:', images)

   const fetchPlace = async() =>{
      try{
        console.log('[Fetched Place] id:', id)
         p = await API.fetchPlaceById(id)
         
         if(p){
           setCurrentPlace(p)
           updateAppData({
             ...appData,
             currentPlace:p
            })
            console.log('[Fetched Place] currentPlace:', currentPlace)
           console.log('[Fetched Place]:', p)
        }else{ console.log('Non-existant'); return}
      }catch(err){console.log(err)}
    }
    // if( !p){
    window.addEventListener('DOMContentLoaded', ()=>{fetchPlace()} )
    // }

      const scrollToReviews = () => {
    if (revRef.current) {
      revRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  useEffect(()=>{
    console.log('useParam id:',id)
    if (id && p != currentPlace){
      fetchPlace(id)
      
      console.log('useEffects p:',p)
    }
    else{return}
    },[p])

  useEffect(()=>{
    window.addEventListener('DOMContentLoaded', ()=>{fetchPlace(id)} )
    
  },[currentPlace])
    // console.log('hmmmms')

  

let status = helpers?.showClosingTime(p?.hours)

    const statusColor = (pre) =>{
        let color = 'red'
        let p = pre?.toLowerCase()
        if( pre === 'open' ){ color = 'green'}
        else if( pre === 'opens' ||  pre === 'closes' ){ color =  'orange'}
        return color
    } 
  // console.log(id)
  useEffect(()=>{
    setShowSearch(false)
      // }else{return}
    },[])
  
  // },[currentPlace,p])
    return (
      // <Def>

          <Container sx={contStyles}>
                 {/* <Box sx={{position:'absolute', right:'0.5rem', top:'0.5rem'}}>
                  <SwipeableDrawerComp color='#000'/>
              </Box> */}
            <div >
              <IconButton  sx={{width: '2.5rem', height:'2.5rem',backgroundColor:'#FFF'}}>
              <Link href = {`/`}>
                <ArrowBackIcon sx={{width: '1.5rem'}}
                />
              </Link>
              </IconButton>
              {/* <LogoCopy /> */}
         
            <div 
            // className = "col-sm-6" 
             style={{display:'flex', flexDirection:'column', gap:'0.5rem', padding:'0.5rem'}}
            >
              {/* style={{"width: 18rem"}}> */}
              <Stack style={{display:'flex', flexDirection:'column', gap:'0.5rem', padding:'0.5rem'}}>

              <Link href = {view === 'home' ?`/place/${p?._id}`:p?.website}>
                <h2>{p?.name}</h2>
              </Link>
             
                  { score ? 
                  <span style={{display:'flex', gap:'0.5rem', alignItems:'center'}}>
                      <Rating style={{alignSelf:'start'}} name="half-rating" defaultValue={score} precision={0.5} />
                    <b>{ score ? Math.round( helpers?.ratingScore(p?.comments)):'0.0'}</b>
                      <Link onClick={()=>{
                        scrollToReviews()
                      }} >({p?.comments?.length} reviews)</Link>
                  </span>
            :'No Ratings Yet'}
              <Box className='row' style={{ display:'flex',gap:'0.25rem'}}>

                <Box className='col-12 col-lg-6'
                    sx={{display:'flex',flexDirection:'row',gap:'0.25rem'}}>
                        {p?.categories?.map((c,i)=>
                            <CategoryChip key={i} category={c}/>
                          )}
                  </Box>
                  <Box className='col-12 col-lg-6'
                  sx={{display:'flex',flexDirection:'row',gap:'0.25rem'}}>
                      {p?.sub_categories?.map((s,i)=>
                          <CategoryChip key={i} category={s} size='small'/>
                          )}
                  </Box>
              </Box>
              {/* {p?.cuisines && <span >
                    {p?.cuisines}
                </span>} */}

                  <span style={{display:'flex',alignSelf:'start', gap:'0.5rem'}}>
                    <span>

                      <b style={{color:statusColor(status?.preMessage)}}>{status?.preMessage}</b>&nbsp;{status?.message}
                    </span>

                    <span style={{cursor:'pointer'}}
                      onClick={()=>{
                        setShowHours(!showHours)
                      }}>
                      <u>
                        {showHours? <ArrowDropDown />:<ArrowDropUp />}
                        {showHours?'hide':'show'} hours</u>
                    </span>
                  </span>
                  {showHours && 
                    <Box sx={{display:'flex', flexDirection:'column',
                      flexWrap:'wrap', margin:'auto', justifyContent:'center',
                      alignContent:'center',
                      height:'200px', gap:'0.25rem',width:'100%'}}>
                      {Object.keys(p?.hours)?.map((day,i)=>{
                        const amPmCheck = (time)=>{
                          if(time ==='Closed')return
                          return Number(time.split(':')[0])<= 12?'am':'pm'
                        }
                        let [close1, close2] = p?.hours[day]?.close?.split(':')
                        // console.log('close1 / close2:',close1 ,close2)
                        let closeTime = Number(close1)  > 12? Number(close1) - 12 : close1 
                         closeTime += close2 ?`:${close2?.toString()}`:''
                          return(
                            <span  key={i} style={{display:'flex',
                                width:'70%',fontSize:'0.85rem', 
                                gap:'0.5rem',color: i % 2 ?'#757575':'#000'}} >
                              <b style={{width:'4rem'}}>{day}:</b>&nbsp;<span style={{textAlign:'right', width:'100%'}}>{p?.hours[day]?.open} {amPmCheck(p?.hours[day]?.open)} - {closeTime} {amPmCheck(p?.hours[day]?.close)}</span> 
                              
                            </span>
                          )
                      })}
                    </Box>
                    }


                  <span style={{display:'flex', alignItems:'center',gap:'0.25rem'}}>
                    <LanguageIcon sx={{color:'#757575'}}/>
                    <b>
                      {p?.location?.city},{p?.location?.state} 
                    </b>
                  </span>
                
              
              </Stack>
                <Box //src={p?.pic} 
                  style={{width: '100%',
                    borderRadius:'5px',
                    display:'flex',
                    padding: '0.5rem',
                    // gap:'0.5rem',
                    boxShadow:'inset 0px 0px 10px 5px rgba(0 ,0, 0, 0.5)',
                    height: 'fit-content',
                    // minWidth:'50%',
                    width:'100%',
                    maxWidth: '600px',
                    margin: 'auto'
                  }}
                //  className="card-img-top" 
                 alt={p?.name}>

{/* 
                  <img src={images[0]} 
                    style={{ borderRadius:'10px  10px 0 0',
                      width:'100%',
                      boxShadow:'inset 0px 0px 10px 5px rgba(0 ,0, 0, 0.5)'
                    }}
                  //  className="card-img-top" 
                    alt={p?.name}>

                 </img> */}


              <Swiper className='mySwiper'
               loop={true}
                navigation={true}
                 thumbs={{ swiper: thumbsSwiper }}
                  modules={[Navigation, Pagination, Controller, Thumbs, FreeMode]}
                  controller={{ control: controlledSwiper }}
                    spaceBetween={0}
                    slidesPerView={1}
                    // onSlideChange={() => console.log('slide change')}
                    // onSwiper={(swiper) => console.log(swiper)}
                  >
                    {images?.map((image,i)=>{
                     return (<SwiperSlide   key={i}> 
                      <img src={image} 
                        style={{ borderRadius:'5px',
                          width:'100%',
                          boxShadow:'inset 0px 0px 10px 5px rgba(0 ,0, 0, 0.5)'
                        }}
                      //  className="card-img-top" 
                        alt={p?.name}>

                    </img> 
                  </SwiperSlide>)
                  })
                }
                  </Swiper>

                  {/* <Swiper
        onSwiper={setThumbsSwiper}
        loop={true}
        spaceBetween={10}
        slidesPerView={4}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper2"
      >
        {images?.map((image,i)=>{
                     return (<SwiperSlide  key={i}> 
                      <img src={image} 
                        style={{ borderRadius:'5px',
                          width:'100%',
                          boxShadow:'inset 0px 0px 10px 5px rgba(0 ,0, 0, 0.5)'
                        }}
                      //  className="card-img-top" 
                        alt={p?.name}>

                    </img> 
                  </SwiperSlide>)})}
      </Swiper> */}
                    {/* <Box sx={{width:'25%'}}>
                      <List sx={{width:'100px',display:'flex',flexDirection: width <= 400 ?'column':'column',gap:'0.5rem'}}>

                        {p?.images?.map((im,i)=>{
                        return(
                          <ListItem key={i}  sx={{width:'50px',borderRadius:'10px' }}
                          >
                            <img src={im} key={i}/>
                          </ListItem>
                          )
                        })}
                      </List>
                    </Box> */}
                 </Box>

                <p className = "text-center card-body">
                  <span>Serving&nbsp;<u>{p?.cuisines} </u> </span> 
                    <b>
                      {p?.showEstablished}
                    </b>  
                {p?.location?.location?.coordinates && <Box sx={{maxHeight:'30vh', overflow:'hidden', 
                borderRadius:'10px', //zIndex:2
                }}>
                  <MapComponent single={[p]} width={'100%'} height={'300px'} />
                </Box>}
                    <p>
                      {p?.about}
                    </p>

                    <Stack className='row' sx={{gap:'0.5rem', flexDirection:'row', margin: 'auto',textAlign:'center'}}>
                        <Box //className=' col-lg-6'
                          sx={goalCardStyles}>
                          <List>
                            <b style={{margin:'auto'}}>goals</b>

                            {p?.goals?.map((g,i)=><ListItem key={i}>
                              <RadioButtonChecked />&nbsp;
                              {g}</ListItem>)}
                          </List>
                      </Box>
                        <Box //className='col-lg-6'
                         sx={goalCardStyles}>
                          <List>
                            <b style={{margin:'auto'}}>challenges</b>
                            {p?.challenges?.map((c,i)=><ListItem key={i}>
                              <RadioButtonChecked />&nbsp;
                              {c}</ListItem>)}
                          </List>
                      </Box>

                    </Stack>
                    {/* <form //action={()=>e.preventDefault() }
                      > */}

                    <Button onClick={(e)=>{
                      // setShowAddComment(!showAddComment)
                      setShowHelper(!showHelper)
                    }}
                    >
                      Able to help ?
                    </Button>
                    {/* </form> */}
                    
                  {/* <h4>Serving {p.cuisines}</h4>     */}
                   
                  {/* <h4>Serving {p.cuisines}</h4>     */}
                </p>
                {/* <a href={`/places/${placeId}/edit`} className="btn btn-warning"> 
                  Edit
                </a>   */}
{/*                 
                <Box sx={{display: 'flex', justifyContent:'space-between'}}> 
                
                <form method = "POST" action = {`/places/${p?._id}?_method=DELETE`}> 
                  <Button type="submit" className="btn btn-danger flex-row" >
                    <DeleteIcon sx={{alignSelf:'end'}}/>
                  </Button>
                </form>     

                <form method = "POST" action = {`/places/${p?._id}/edit?_method=GET`}> 
                  <Button type="submit" className="btn btn-warning" style={{color:'#000'}} >
                    Edit
                  </Button>
                </form> 
                </Box> */}

            </div>
            <hr />
            <Box sx={{display:'flex',flexDirection:'column', width:'100%',
              zIndex:999999,margin:'auto',
              justifyContent:'center'}}>
              <Container  
                ref={revRef}
                sx={{ display:'flex', width:'100%',justifyContent:'center',
                flexDirection:'column',gap:'0.5rem',margin:'auto'}}>
                 {showAddComment&& <span 
                style={{cursor:'pointer', alignSelf:'end'}}
                onClick={()=>{
                  setShowAddComment(!showAddComment)
                }}
                // onPress={()=>{
                //   setShowAddComment(!showAddComment)
                // }}
                >x click to close!</span>}
                {/* <form action=''> */}

                {showAddComment? <AddComment /> : <Button
                  onClick={(e)=>{
                    e.preventDefault() 
                        // window.alert('hmmmms')
                    // console.log('showAddComment:',showAddComment)
                    setShowAddComment(!showAddComment)
                  }}>share your experience!</Button>}
                {/* </form> */}
              <h2>Reviews</h2>

             
                <Box sx={{ display:'flex', width:'80%', minWidth:'350px',justifyContent:'center',alignSelf:'center',
                    alignContent:'center',//border:'1px solid red',
                     padding:'0.5rem',
                    flexDirection:'column',gap:'0.5rem',margin:'auto'}}>

                {comments}
                </Box>
              </Container>
            </Box>
            </div>
            <hr />
            
          </Container>
      // </Def>
      
    )
}
export default Show
