import React, {useContext,useEffect, useState} from 'react'
import Businesses from './components/Businesses';
import NavBar from './components/NavBar';
import { AppProvider, AppContext } from './context/AppProvider';
import Home from './views/home';
import Show from './views/show';
import Edit from './views/edit';
import NewForm from './views/new';
import { Route, Routes, BrowserRouter , useParams,useLocation} from 'react-router-dom';
import { Box } from '@mui/material';
import Banner from './components/Banner';

function App() {
  // const {currentPlace, setCurrentPlace} = useContext(AppContext)

  return (
    <div className="App">
      <AppProvider>
        <NavBar />
        <Banner />

        <BrowserRouter >
          <Routes>
              <Route path ="/" element ={<Home />} exact/> 
              <Route path ="/places" element ={<Businesses/>} /> 
              <Route path ="/place/:id" element ={<Show/>} /> 
              <Route path ="/place/:id/edit" element ={<Edit />} /> 
              <Route path ="/places/new/" element ={<NewForm/>} /> 
          </Routes>
        </BrowserRouter>
      </AppProvider>
    </div>
  );
}

export default App;
