import { Box, Button, Card, Container, Input, Paper, Rating, Stack, TextField } from '@mui/material'
import React, { useState, useContext } from 'react'
import * as API from '../utils/APICalls'
import * as helpers from '../utils/helpers'
import { AppProvider, AppContext, AppState } from "../context/AppProvider";

const AddComment = (props) => {
  const { view, prevCom } = props
    const { updateAppData, appData, userData,
      error, setError,
        places, setCurrentPlace, currentPlace,
        setPlaces,} = useContext(AppContext)
    const [ isSaving , setIsSaving ] = useState(false)

  const p = currentPlace
  
    const [ commentState, setCommentState ] = useState({
      author: userData?._id,
      business: p?._id,
      attachment: view === 'reply' 
      ?
          {reply:true,prev_id:prevCom?._id}
      :  {reply:false}
    })
   
  return (
    <Paper sx={{display:'flex', flexDirection:'column',width:'100%',padding:'0.5rem',
    justifyContent:'center',alignContent:"center", gap:'0.5rem'}}>
      <Box>
        
      </Box>
      {view !== 'reply' && <h2>Tell Us About Your Experience!</h2>}
            <form 
            // action={`/places/${p?._id}/comment`}
            // action={async()=>{
            //   try{

            //     await API.createComment(commentState)
            //     const ps = await API.fectchPlaceById(p?._id)
            //     if(ps){
            //       setPlaces(ps)
            //     }
            //   }catch(err){console.log('err')}
            // }}
            //  method="POST" 
            style={{
              display: 'flex',
              flexDirection:'column',
              gap:'0.5rem',
              width:'100%'}}>
              <div 
            //   className="row" 
              style={{display:'flex', flexDirection:'column',width:'100%',margin:'auto', gap:'0.5rem'}}>
                 {view !== 'reply' && <div
                //   className="form-group col-sm-4"
                 >
                 
                <div className="row">
                <div className="form-group col-md-4">
                  <label htmlFor="pic">Have a pic?  <em style={{fontSize:'0.75rem'}}>(optional)</em></label>
                  <input  onChange={(e)=>{
                          setCommentState({
                            ...commentState,
                            pic: e.target.value
                          })
                        }} type='file' id="pic" name="pic" className="form-control" />
                </div>
               
               
              </div>
              <Stack>

                  <Rating style={{alignSelf:'start'}}
                    required ={view === 'reply' ? false :true}
                        onChange={(e)=>{
                          setCommentState({
                            ...commentState,
                            stars: e.target.value
                          })
                        }}
                         name="half-rating"
                        defaultValue={commentState?.stars} precision={0.5} />
                 <em style={{fontSize:'0.75rem'}}>*(Required to post comment)</em>
              </Stack>
                </div>}
                <div 
                // className="form-group col-sm-12"
                >
                  {/* <label htmlFor="content">Content</label> */}
                  <TextField
                    multiline
                    label={view !=='reply' ?'comment':'comment'}
                    // label={view !=='reply' ?'comment':'reply'}
                    rows={4}
                      // sx={{borderRadius:'5rp'}}
                      onChange={(e)=>{
                            setCommentState({
                              ...commentState,
                              content: e.target.value
                            })
                          }}
                  id="content" name="content" className="form-control"/>
                </div>
              </div>
              {error?<b style={{color:"red",margin:'auto'}}>{error}</b>:''}
              <input disabled={isSaving} className="btn btn-primary" value={`Add${isSaving? 'ing':''} Comment`}
              onClick={async()=>{
                if(userData?._id){
                  setIsSaving(true)
                  try{
                    await API.createComment(commentState)
                    const pl = await API.fetchPlaceById(p?._id)
                    if(pl){
                      setCurrentPlace(pl)
                    }
                  }catch(err){console.log('err')}
                  setIsSaving(false)
                }else{
                  setError('Please login to post')
                  await  helpers.sleep(10000)
                  setError('')
                }
            }} />
         </form>
        </Paper>
  )
}

export default AddComment