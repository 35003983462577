import React, { useEffect, useState, useContext } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import useWindowDimensions from "../hooks/windowDimensions";

// import  * as globals from '../../utils/globals'
import * as globals from '../views/globals'
import LocationComponent from './LocationComponent';
import { Box, Container, Input, Stack, Typography } from '@mui/material';
import LogoCopy from './LogoCopy';
import { AppContext } from "../context/AppProvider"; 
import zIndex from '@mui/material/styles/zIndex';
import { ArrowDownward, ArrowDropDown, ArrowDropUp, Label, Search } from '@mui/icons-material';
import SwipeableDrawerComp from './SwipeableDrawer';
import MapComponent from './MapComponent';
 
// const globals = require( '../../utils/globals')

// require('dotenv').config()

const NavSearch = () => {
  const { height, width } = useWindowDimensions();
  const { updateAppData, appData, currentPlace, setCurrentPlace, error, setError,
        searchRadius, setSearchRadius,showSearch, setShowSearch,
        searchCategory, setSearchCategory, userData,
        setUserData, isSearching,
        places, setPlaces,userLocation, setUserLocation} = useContext(AppContext)
  const g = globals
  const busList = g?.busTypeList

    // console.log("busList",globals.busTypeList)
  const [ options, setOptions ] = useState([]);
  const [ userZip, setUserZip ] = useState(27615);
  const [ userCheck, setUserCheck ] = useState(false);
  const [ search, setSearch ] = useState('');
  const cat_options = Object.keys(busList)?.map((k,i)=>k)
  const GEOAPIFY_API ='53e573c916334ef482bf4f0282afb1cc'

  let catList = Object.keys(busList)?.map((key,i)=>{
        return key 
    })

    const inputStyle = {
        // borderRadius:'50px',
        borderRadius:'5px',
        decoration: 'none',
        background: 'rgba( 255,255,255,0.3)',
        backdropFilter: 'blur(5px)',
        width:'100%',
        minWidth:'150px',
        // zIndex:999999,
    }

 const [categories, setCategories] = useState([]);
 

 useEffect(() => {
     if( !userCheck ){
      if(localStorage.getItem('BOCUser')){
        setUserData({...JSON.parse(localStorage.getItem('BOCUser'))})
         updateAppData({
           ...appData,
           userData
         })
        setUserCheck(true)
        console.log('localStorage:',JSON.parse(localStorage.getItem('BOCUser')) )
      }
     }else{
      setUserCheck(true)
      return}
    
    
  }, [userData, userCheck]);

  // const fetchPlaces = async (query) => {
  //   if (query.length < 3) return; // Avoid making requests for short queries
  //   try {
  //     const response = await axios.get('https://api.geoapify.com/v2/places', {
  //       params: {
  //         categories: 'catering.restaurant', // Example searchCategory
  //       //   text: query,
  //         apiKey: GEOAPIFY_API, // Replace with your Geoapify API key
  //       },
  //     });
  //     if(response){
  //       console.log('response:',response)
  //         setOptions(response.data.features.map((feature) => feature.properties.name));

  //     }else{return}
  //   //   console.log("options", options);
  //   } catch (error) {
  //     console.error("Error fetching data from Geoapify", error);
  //   }
  // };

  useEffect(()=>{

  },[search])
  useEffect(()=>{
    if(window.location.href.includes('/place/')){
        setShowSearch(false)
    }
  },[showSearch])

    useEffect(()=>{

    },[places])

  return (
    <Box
    className='nav'
     sx={{display:'flex',
        postion:'relative',
        // flexDirection: width <= 600 ?'column':'row',
        flexDirection: 'column',
        alignItems:'center', gap:'0.5rem', minWidth:'350px',
        backgroundColor:'rgba(255,255,255,0.2)',
        // backgroundColor:'rgba(0,0,0,0.7)',
        borderRadius:'0px 0px 5px 5px',
        backdropFilter:'blur(5px)',
        boxShadow:'0px 0px 10px 3px rgba(0, 0,0,0.5)',
        // border:'2px solid #000',
        padding:'0.5rem',
        width:'100%',
        maxWidth:'100vw',
        zIndex:100,
        alignContent:'center',
        alignItems:'center',
        gap:'0.5rem',
        // height:'100vh'
        // maxHeight:'20vh'
        // maxHeight:'60vh'
        // margin:'auto'

    }}>
      {/* <Container sx={{
          display:'flex',flexDirection:'column',
          width:'100%', gap:'0.5rem',
          maxHeight:'400px'
          }}> */}

         
      <Box sx={{//height:'2.5rem', 
        margin:'0.5rem auto'}}>
        {showSearch &&  !window.location.href.includes('/place/') &&<ArrowDropUp className='rot-180' sx={{
          position:'absolute',
          left:'0.5rem',
          top: '0.5rem',
          color: '#000',
          cursor: 'pointer',
          zIndex: 99,
          fontSize:'2.5rem'}}
          onClick={()=>{
            setShowSearch(!showSearch)
          }}
          />}
      {!showSearch  &&  !window.location.href.includes('/place/') &&<Search className='rot-180' sx={{
          position:'absolute',
          left:'0.5rem',
          top: '1rem',
          color: '#000',
          cursor: 'pointer',
          zIndex: 99,
          fontSize:'2.5rem'}}
          onClick={()=>{
            setShowSearch(!showSearch)
          }}
          />}
          <Box sx={{
            // backgroundColor:'rgba(255, 255,255, 0.5)',
            backgroundColor:'rgba(80,45,22,1)',
            // backgroundImage: 'linear-gradient(to bottom,rgba(66, 42, 11, 1), rgba(255, 255, 255, 0.1) 80%, rgba(255, 255, 255, 0) 100%)',
            backdropFilter:'blur(5px)',
            position:'absolute',
            left:'50%',
            top:'0px',                      
            display:'flex',
            flexDirection:'column',
            width:'100%',padding:'0.5rem',
            transform:'translate(-50%,10%)',
            height: '100px',
            borderRadius:'10px',
            // width:'200px',
        //   minWidth:'200px',
        //   maxWidth:'500px',
            justifyContent:'center',
            alignContent:'center',
            alignItems:'center',
            border: '2px solid rgb(80,45,22)',
            boxShadow:'0px 0px 10px 3px rgba(0, 0,0,0.5)',
            overflow:'hidden'
            }}>
               <Box sx={{
            // height:'500px',
                      position:'absolute',
                      // width:'100%',
                      bottom:'0rem',
                      right:'0.5rem',
                      margin:'0.5rem auto',
                      // transform:'translate(-50%,0)',
                      // top:'50px',
                      fontSize:'0.75rem',
                      zIndex:999
                  }}>
                  { userData?.first ? `Welcome, ${userData?.first}!`:''}
              </Box> 
            <Box sx={{
                    position:'absolute',
                // left:'50%',
                // top:'25px',                      
                    display:'flex',
                    flexDirection:'column',
                    width:'100%',
                    padding:'0.5rem',
                    // transform:'translate(-50%,10%)',
                    backgroundColor:'rgba(255,255,255,0.5)',
                    // boxShadow:'inset 0px 0px 10px 3px rgba(126,171,111,1)',
                    // boxShadow:'inset 0px 0px 10px 5px rgba(229,187,58,0.7)',
                    // backgroundImage: 'linear-gradient( 40deg,hsl(31deg 29% 39%) 0%, hsl(27deg 59% 42%) 8%, hsl(20deg 80% 49%) 17%, hsl(24deg 78% 51%) 28%, hsl(30deg 67% 53%) 39%, hsl(35deg 67% 58%) 51%, hsl(41deg 79% 64%) 63%, hsl(43deg 91% 65%) 76%, hsl(32deg 100% 49%) 89%, hsl(0deg 100% 50%) 100%)',
                    // opacity:'0.75',
                    // height: '4rem',
                    // height: '100px',
                    // borderRadius:'100%',
                    // width:'100px',
                //   minWidth:'200px',
                //   maxWidth:'500px',
                    justifyContent:'center',
                    alignContent:'center',
                    alignItems:'center',
                    // border: '2px solid #000',
                    }}>
        
                <LogoCopy width={'150px'} 
                />
            </Box>
           
          </Box>
          {/* <Container sx={{
          display:'flex',flexDirection:'column',
          width:'100%', gap:'0.5rem',
          maxHeight:'400px'
          }}> */}

       
           <Box style={{
                zIndex:99, 
                position:'absolute',
                top:'0.5rem', 
                right:'0.5rem', 
                // alignSelf:'end'
                }}>
            {/* <LogoCopy /> */}
            <SwipeableDrawerComp color={'#000'}/>
        </Box>
      </Box>


      
        {showSearch && <Stack sx={{
          position:'relative',
              width:'100%',padding:'0.5rem',
              backgroundColor:'rgba(255,255,255,1)',
              borderRadius:'5px',
              minWidth:'200px',
              border: '1px solid #000',
              marginTop:'2rem',
              textAlign:'center',
              zIndex: 10,
              maxHeight:'500px'
              }}>
                <Typography sx={{fontWeight:'bold'}}>
                  What are WE looking for?
                </Typography>

            <Autocomplete
                // style={{width:'100%', zIndex:99999}} 
                style={inputStyle} 
                autoFocus={true}
                  freeSolo
                // sx={{border:'none'}}
                sx={{
                  '& .MuiAutocomplete-popper': {
                        zIndex: 9999, // Ensure this is higher than other elements
                      },
                      '& .MuiAutocomplete-inputRoot': {
                        zIndex: 11, // Ensure input is above background
                      }
                    }}
                
                  options={cat_options}
                  onInputChange={(event, newInputValue) => {
                      setSearchCategory(newInputValue)
                }}
              renderInput={(params) => <TextField style={inputStyle}{...params} label={searchCategory?'':"Category"} />}
            />
            <Box sx={{display:'flex',maxWidth:'400px',margin:'auto', gap:'0.5rem'}}>

            <Stack>
                <b style={{ fontSize:'0.8rem', flexWrap:'wrap' }}>

                  {`Search Radius`}
                </b>
                <p style={{ fontSize:'0.8rem', flexWrap:'wrap' }}>

                  {`${searchRadius} mi.`}
                </p>
              <Input 
              type='range' 
              // label={`Search Radius ${searchRadius} mi.`}
              min={0.5}
              max={100}
              step={0.5}
              sx={{color:'#FFF', margin:'1rem auto',backgroundColor:'#FFF'}}
              onChange={(e)=>{setSearchRadius(Number(e.target.value))}
            }
            defaultValue={searchRadius}
            
            />
            </Stack>
          <TextField 
            type='zip' 
            label={`Zip`}
            required={!userLocation}
            min={0.5}
            max={100}
            incriment={0.5}
            sx={{ margin:'1rem auto',backgroundColor:'#FFF'}}
            // onChange={
            //   (e)=>{ setUserZip(e.target.value)}
            // }
            defaultValue={userZip}
            value={userZip}//!----turn off for use
          
          />
            </Box>
        {places && <Container sx={{position: 'relative', bottom: 0}}>
              <MapComponent  height={'250px'} width={'100%'}/>
          </Container>}
          <Box sx={{alignSelf:'end', display:'flex', gap:'0.5rem', alignItems:'center'}}>
        {!isSearching ? <span> {places?.length > 0 && <ArrowDropDown sx={{
                color: '#000',
                cursor: 'pointer',
                zIndex: 99,
                fontSize:'2.5rem'}}
                onClick={()=>{
                  setShowSearch(!showSearch)
                }}
                />}
                {places? places?.length: 0} results found</span>:'Searching...'}
            <LocationComponent zip={userZip} />
          </Box>
        </Stack>}
      {/* </Container> */}
    </Box>
  );
};

export default NavSearch;