import React from 'react'
import Chip from '@mui/material/Chip';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import SpaIcon from '@mui/icons-material/Spa';
import CoffeeIcon from '@mui/icons-material/LocalCafe';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import CarRepairIcon from '@mui/icons-material/CarRepair';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import BookIcon from '@mui/icons-material/Book';
import NightlifeIcon from '@mui/icons-material/Nightlife';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LocalBarIcon from '@mui/icons-material/LocalBar';
import LocalFloristIcon from '@mui/icons-material/LocalFlorist';
import BuildIcon from '@mui/icons-material/Build';

// Define a mapping of categories to icons
const categoryIconMap = {
  Restaurants: <RestaurantIcon />,
  "Beauty & Spas": <SpaIcon />,
  Cafes: <CoffeeIcon />,
  "Active Life": <FitnessCenterIcon />,
  Shopping: <ShoppingCartIcon />,
  "Health & Medical": <LocalHospitalIcon />,
  Automotive: <CarRepairIcon />,
  "Home Services": <HomeRepairServiceIcon />,
  "Financial Services": <AttachMoneyIcon />,
  "Arts & Entertainment": <MusicNoteIcon />,
  "Books & Culture": <BookIcon />,
  Nightlife: <NightlifeIcon />,
  Food: <LocalGroceryStoreIcon />,
  Education: <LocalLibraryIcon />,
  // Add more categories and corresponding icons as needed

  "Soul Food": <FastfoodIcon />,
  "Comfort Food": <FastfoodIcon />,
  "Day Spas": <SpaIcon />,
  "Skin Care": <SpaIcon />,
  "Coffee & Tea": <CoffeeIcon />,
  "Live Music": <MusicNoteIcon />,
  "Hair Salons": <SpaIcon />,
  "Hair Stylists": <SpaIcon />,
  Bookstores: <BookIcon />,
  "Cultural Center": <LocalLibraryIcon />,
  Gyms: <FitnessCenterIcon />,
  "Personal Training": <FitnessCenterIcon />,
  Grocery: <LocalGroceryStoreIcon />,
  "International Grocery": <LocalGroceryStoreIcon />,
  "Financial Advising": <AttachMoneyIcon />,
  "Investment Services": <AttachMoneyIcon />,
  "Dance Studios": <MusicNoteIcon />,
  "Performing Arts": <MusicNoteIcon />,
  "Farmers Market": <LocalFloristIcon />,
  "Local Produce": <LocalFloristIcon />,
  "Dance Clubs": <LocalBarIcon />,
  Bars: <LocalBarIcon />,
  "Beauty & Cosmetics": <SpaIcon />,
  Skincare: <SpaIcon />,
  "Specialty Coffee": <CoffeeIcon />,
  "Auto Repair": <CarRepairIcon />,
  "Car Dealers": <CarRepairIcon />,
  "Electricians": <BuildIcon />,
  Plumbers: <BuildIcon />,
  Contractors: <BuildIcon />,
};


const CategoryChip = ({ category }) => {
  // Get the icon for the given category
  const icon = categoryIconMap[category] || null;

  return (
    <Chip
      label={category}
      size="small"
      icon={icon}
    />
  );
};
export default CategoryChip