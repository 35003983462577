export const haversineDistance = (coord1, coord2)=> {
    // longitude , latitude
    // console.log('coords 1:',coord1)
    // console.log('coords 2:',coord2)
  const R = 3956; // Radius of Earth in miles
  const [lat1, lon1] = coord1;
  const [lat2, lon2] = coord2;

  const toRadians = (degrees) => degrees * (Math.PI / 180);

  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);

  const a = Math.sin(dLat / 2) ** 2 +
            Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
            Math.sin(dLon / 2) ** 2;

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c;
}

const findNextOpenDay = (startDay,hours,currentTime,daysMap)=> {
    const daysOfWeek = ['sun', 'mon', 'tue', 'wed', 'thr', 'fri', 'sat'];
    let dayIndex = daysOfWeek.indexOf(startDay);
    for (let i = 1; i <= 7; i++) {
      // const nextDayIndex = (dayIndex + i) % 7;
       let nextDayIndex = 0
       if(dayIndex + i  <=7){
        nextDayIndex = dayIndex + i 
       }else{nextDayIndex = dayIndex + i %7}
       
      const nextDay = daysOfWeek[nextDayIndex];
      const nextDayHours = hours[nextDay];
      if (nextDayHours && nextDayHours.open.toLowerCase() !== 'closed') {
        const [nextOpenHour, nextOpenMinute] = nextDayHours.open.split(':').map(Number);
        const nextOpenTime = new Date();
        nextOpenTime.setDate(currentTime.getDate() + i);
        nextOpenTime.setHours(nextOpenHour, nextOpenMinute, 0);
        const nextDayName = Object.keys(daysMap).find(key => daysMap[key] === nextDay);
        return { status: 'closed', preMessage: 'Closed', message: `until ${nextOpenTime.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).toLowerCase()} ${nextDayName.toLowerCase()}.` };
      }
    }
    return { status: 'closed', preMessage: 'Closed', message: 'for the foreseeable future' }; // If no open days are found
  }
export const showClosingTime = (hours) =>{
   if (!hours) {
    return { status: 'unknown', preMessage: 'Hours', message: 'not available' };
  }

  const daysMap = {
    Sun: 'sun',
    Mon: 'mon',
    Tue: 'tue',
    Wed: 'wed',
    Thu: 'thr',
    Fri: 'fri',
    Sat: 'sat'
  };

  const currentDay = daysMap[new Date().toLocaleString('en-US', { weekday: 'short' })];
  const currentTime = new Date();

  // Check if the current day exists in the hours object
  if (!hours[currentDay]) {
    return findNextOpenDay(currentDay);
  }

  const todayHours = hours[currentDay];

    // Check if todayHours has the expected structure
  if (!todayHours || !todayHours.open || !todayHours.close) {
    return { status: 'unknown', preMessage: 'Hours', message: 'not available for today' };
  }

  const [openHour, openMinute] = todayHours.open.split(':').map(Number);
  const [closeHour, closeMinute] = todayHours.close.split(':').map(Number);

  const openTime = new Date(currentTime);
  openTime.setHours(openHour, openMinute, 0);

  const closeTime = new Date(currentTime);
  closeTime.setHours(closeHour, closeMinute, 0);

  if (openTime <= currentTime && currentTime <= closeTime) {
    const minutesUntilClose = Math.floor((closeTime - currentTime) / 60000);
    if (minutesUntilClose < 60) {
      return { status: 'closingSoon', preMessage: 'Closes', message: `in ${minutesUntilClose} mins.` };
    }
    return { status: 'open', preMessage: 'Open', message: `until ${closeTime.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).toLowerCase()}` };
  } else if (currentTime < openTime) {
    const minutesUntilOpen = Math.floor((openTime - currentTime) / 60000);
    if (minutesUntilOpen < 60) {
      return { status: 'openingSoon', preMessage: 'Opens', message: `in ${minutesUntilOpen} mins.` };
    }
    return { status: 'closed', preMessage: 'Closed', message: `until ${openTime.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).toLowerCase()}` };
  } else {
    return findNextOpenDay(currentDay,hours,currentTime,daysMap);
    // return{ status: 'closed', preMessage: 'Closed', message: `but there should be more ${currentDay}`} //findNextOpenDay(currentDay);
  }

  

};

export const ratingScore = (comments) =>{
 const tot = comments?.reduce((total,c)=>total + c?.stars,0)
//  console.log('ratingScore',tot / comments?.length)
  return tot / comments?.length
}

export const compileImages = (place) =>{
   let pics = [place?.pic]
   if(place?.images?.length >0){
       pics.push(place?.images)
   }
   return pics
}

export const getDisplayDate = (obj) => {
    const now = new Date();
    const createdAt = new Date(obj.createdAt);

    if (isNaN(createdAt.getTime())) {
        return 'Invalid date';
    }

    const diffInMs = now - createdAt;
    const diffInSecs = Math.floor(diffInMs / 1000);
    const diffInMins = Math.floor(diffInMs / (1000 * 60));
    const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
    const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

    if (diffInSecs < 60) {
        return `${diffInSecs} secs ago`;
    } else if (diffInMins < 60) {
        return `${diffInMins} mins ago`;
    } else if (diffInHours < 24) {
        return `${diffInHours} hrs ago`;
    } else if (diffInDays < 7) {
        return `${diffInDays} days ago`;
    } else {
        return createdAt.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
    }
};

export const postName = (obj) => {

  // return `${this.name} has been serving ${this.location.city}, ${this.location.state}  ${this.cuisines} since ${this.founded}.`
  return obj?.preferred ? obj?.preferred : `${obj?.first} ${obj?.last? obj?.last[0]:''}.`
}

export const  sleep = (time) =>{
                return new Promise(resolve => {
                    setTimeout(resolve, time)
                    // setAnimationFrame instread for event loop purposes
                })  
            }
