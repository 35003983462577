
import React, { useState, createContext ,useContext, useEffect} from "react";

// Create a new context
export const AppContext = createContext();

// Create a context provider component
export const AppProvider = ({ children }) => {
    const [ appData, setAppData ] = useState(false)
    const [ userData, setUserData ] = useState({})
    const [ error, setError ] = useState(null)
    const [ userLocation, setUserLocation ] = useState()
    const [ searchRadius,setSearchRadius ] = useState(20)
    const [ searchCategory, setSearchCategory ] = useState('')
    const [ places, setPlaces ] = useState(false)
    const [ currentPlace,  setCurrentPlace ] = useState(false)
    const [ isMenuOpen, setIsMenuOpen ] = useState(false)
    // const [ token, setToken ] = useState(false)
    const [ showSearch, setShowSearch] = useState(true)
    const [ isNewSearch, setIsNewSearch ] = useState(false)
    const [ isSearching, setIsSearching ] = useState(false); 


  

  const [subView, setSubView] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  

  const updateAppData = (newData) => {
    setAppData((prevState) => ({
        userData, 
        // token,
        isMenuOpen, 
        error, 
        searchRadius,
        searchCategory,
        currentPlace,  
        isLoggedIn, 
        subView,
        userLocation,
        places,
        showSearch, 
        isNewSearch,
        isSearching,
        ...userData,
        ...prevState,
        ...newData,   
    }));
    
  };
  useEffect(()=>{
   
  },[userData])

  return (
    <AppContext.Provider value={{ 
        appData, 
        updateAppData,
        userData, 
        setUserData,
        // token, 
        // setToken,
        isMenuOpen, 
        setIsMenuOpen,
        error, 
        setError,
        searchRadius,
        setSearchRadius,
        searchCategory, 
        setSearchCategory,
        currentPlace,  
        setCurrentPlace,
        userLocation, 
        setUserLocation,
        isLoggedIn, 
        setIsLoggedIn,
        subView, 
        setSubView,
        places, 
        setPlaces,
        showSearch, 
        setShowSearch,
        isNewSearch, 
        setIsNewSearch,
        isSearching, 
        setIsSearching  
        }}>
      {children}
    </AppContext.Provider>
  );
};
export const AppState = () => {
  return useContext(AppContext);
};