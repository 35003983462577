import React, { useEffect, useRef, useState, useContext } from "react";
// import { Cloudinary } from "cloudinary-react";
import axios from 'axios'
import EnvRoute from '../middleware/envRoute';
const ENDPOINT = EnvRoute('prod'); 

 const config = {
            headers: {
                "Content-type" : "application/json",
                // Authorization: `Bearer ${userObj?.token}`,
            },
        };

export const fetchPlaces  = async(term) =>{
    console.log("[fetching Places] for:", term)
    try{
        // const id = userObj?._id
        // const config = {
        //     headers: {
        //         "Content-type" : "application/json",
        //         // Authorization: `Bearer ${userObj?.token}`,
        //     },
        // };
        const data  = await axios.get(`${ENDPOINT[0]}/places/`,{term: term}, config)
        console.log("[data] data: ", data?.data)
        return data?.data
                
        }catch(err){console.log('[fetchPlaces]: ',err)}
    }
export const fetchPlaceById  = async(id) =>{
    const _id = id?.toString()
    
    console.log("[fetching Places] for:", _id)
    try{
        // const id = userObj?._id
       
        const response  = await axios.get(`${ENDPOINT[0]}/places/id/${_id}`, config)
        console.log("response", response)
        if(response.data){
            console.log("[data] data: ", response.data)
            return response.data
        }else{console.log('[fetchPlaces]: ---No Data---' )}
        
    }catch(err){console.log('[fetchPlaces]: ',err)}
}

export const fetchNearbyPlaces = async ( lat, lon, radius, zip,cat) => {
    // Make API call to backend to fetch nearby places
    try{
    const data = await axios.get(`${ENDPOINT[0]}/places/nearby/`,{
             params: {
                lat: lat,
                lon: lon,
                radius: radius,
                zip: zip,
                cat: cat
            }
        }, config)

        if(data){
            console.log("[fetchNearbyPlaces] data: ", data)
            return data?.data
        }else{console.log('[fetchNearbyPlaces]: ---No Data---' )}

    }catch(err){console.log('[fetchNearbyPlaces]: ',err)}
    
  };


export const createComment  = async(obj) =>{
    console.log("[Creating Comment] for:", obj)
    try{
        // const obj = userObj?._id
       
        const data  = await axios.post(`${ENDPOINT[0]}/comments/create/`,{...obj}, config)
        if(data){
            console.log("[createComment] data: ", data?.data)
            return data?.data
        }else{console.log('[createComment]: ---No Data---' )}
        
    }catch(err){console.log('[createComment]: ',err)}
}

export const fetchCommentsByPlaceId  = async(id) =>{
    console.log("[fetching Comments] for:", id)
    try{
        const data  = await axios.get(`${ENDPOINT[0]}/comments/`,{business: id}, config)
        if(data){
            console.log("[data] data: ", data?.data)
            return data?.data
        }else{console.log('[fetchCommentsByPlaceId]: ---No Data---' )}
        
    }catch(err){console.log('[fetchCommentsByPlaceId]: ',err)}
}
/*--------------------
------Auth Calls-------
----------------------*/
export const Login = async (obj) => {

     const config = {
          headers: {
            "Content-type": "application/json",
          },
        };
     try {
      console.log('Login obj:', obj)
      const  data  = await axios.post(
        `${ENDPOINT[0]}/auth/login`,
        {
          email: obj.email,
          password: obj.password
        },//config,
        // { withCredentials: true }
      );
     return data
    } catch (error) {
      console.log(error);
    }
  

}
export const signup = async (userObj) => {
     const config = {
          headers: {
            "Content-type": "application/json",
          },
        };
     try {
      const {data}  = await axios.post(
        `${ENDPOINT[0]}/auth/signup`,
        {
          ...userObj
        },config,
        { withCredentials: true }
      );
    //   console.log('Data:',data)
     return data
    } catch (error) {
      console.log(error);
    }
  

}
/*--------------------
------User Calls-------
----------------------*/

/*----------CREATE------------*/
export const createUser = async (userObj) => {
    
    // console.log('{createUser] pre-data:',userObj);
    const { first ,last ,preferred,email ,password ,confirmPassword ,phone,isOnline,pic,linkage }= userObj
   
   
    try {
        const config = {
          headers: {
            "Content-type": "application/json",
          },
        };
      const { data } = await axios.post(
        `${ENDPOINT[0]}/user/reg`, userObj, config
      );
    //   console.log('[createUser] data:',data);
    //   localStorage.setItem("QauRaUser", JSON.stringify(data));
      return data
   
    } catch (err) {console.log('[Create User Error]: ',err)
    }
  };
/*----------READ------------*/
export const userLogin = async (userObj) => {
   

   const {email ,password  }= userObj
    
  
      if ( !email || !password ) {
        return 'missing credentials';
      }
   
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      const { data } = await axios.post(
        `${ENDPOINT[0]}/user/login`,
        {
          email,
          password,
        },
        config
      );
      return data
  
      
      } catch (err) {
        console.log('[User Login Error]: ',err)
        return 'unauthorized'
      
    }
        
    }

export const userByID = async (userObj) => {
   

   const {_id ,password,token  }= userObj
    
  
    //   if ( !email || !password ) {
    //     return 'missing credentials';
    //   }
   
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userObj?.token}`

        },
      };
      const { data, } = await axios.post(
        `${ENDPOINT[0]}/user/info`,
        {
          _id,
          token
        },
        config
      );
      return data
  
      
      } catch (err) {
        console.log('[User Login Error]: ',err)
        return 'unauthorized'
      
    }
        
    }

    export const userByEmail = async (email) => {
   

    
  
      if ( !email ) {
        return 'missing email';
      }
   
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        //   Authorization: `Bearer ${userObj?.token}`

        },
      };
      const { data, } = await axios.post(
        `${ENDPOINT[0]}/user/status`,
        {
         email
        },
        config
      );
      return data
  
      
      } catch (err) {
        console.log('[User Check Error]: ',err)
        return 'error'
      
    }
        
    }

/*----------UPDATE------------*/
export const updateUser = async(userObj) =>{
        try{
            console.log('[Update User token]: ',userObj?.token)
            const config = {
                headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userObj?.token}`,
                cookies:  {token: userObj?.token}
                },
            };
            const { data } = await axios.put(
                `${ENDPOINT[0]}/user/update`,
                {
                //   email: userObj?.email,
                    ...userObj,
                },
                config
                );
            return data.data
        }catch(err){console.log('[Update User Error]: ',err)}  
    }   

export const updateLogout = async(userObj) =>{
        try{
            const config = {
                headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userObj?.token}`
                },
            };
            const { data } = await axios.put(
                `${ENDPOINT[0]}/user/update`,
                {
                  email: userObj?.email,
                    ...userObj,
                },
                config
                );
            return data
        }catch(err){console.log('[Update User Error]: ',err)}  
    }   

export const forgotPassword = async(email) =>{
        try{
            const config = {
                headers: {
                "Content-type": "application/json",
                // Authorization: `Bearer ${userObj?.token}`
                },
            };
            const { data } = await axios.post(
                `${ENDPOINT[0]}/user/forgot-password`,
                {email},
                config
                );
            return data
        }catch(err){console.log('[Update User Error]: ',err)}  
    }   

export const resetPassword = async(userObj) =>{
        try{
            const config = {
                headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userObj?.token}`
                },
            };
            const { data } = await axios.post(
                `${ENDPOINT[0]}/user/reset-password`,
                {
                  email: userObj?.email,
                    ...userObj,
                },
                config
                );
            return data
        }catch(err){console.log('[Update User Error]: ',err)}  
    }   

    export const toggleCommentLike = async (commentId, userId) => {
  try {
    const response = await axios.put(`${ENDPOINT[0]}/comments/toggle-like`, {
      commentId,
      userId
    }, config);

    if (response.data) {
      console.log("Like toggled successfully:", response.data);
      return response.data;
    } else {
      console.log('Toggle like: No Data');
    }
  } catch (err) {
    console.error('Error toggling like:', err);
  }
};