import React,{useState, useContext, useEffect} from 'react'
import { AppContext } from "../context/AppProvider";
import { CheckBox } from '@mui/icons-material'
import { Box, Button, TextField ,IconButton, InputAdornment} from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import * as API from '../utils/APICalls'

function Login() {
    const [ show, setShow ] = useState(false)

    const [ loginState, setLoginState ] = useState({

    })
    const { currentPlace, setCurrentPlace, appData, 
        userData, setUserData,
        updateAppData,userLocation } = useContext(AppContext)
    const [ loading, setLoading] = useState(false)
    const [ actionMessage, setActionMessage] = useState()

      const handleShowClicK = (e) =>{
        e.preventDefault()
        setShow(!show)
  }
  
      const handleError = (err) =>
      setActionMessage({
        type:'error',
        message: err
    })
   
    const handleSuccess = (msg) =>
        setActionMessage({
           type:'success',
            message: msg
        })

     const handleLogin = async (e) => {
      const { email, password } = loginState
        e.preventDefault()
        setLoading(true)
        handleError('')
          if ( !email || !password ) {
        handleError('please fill all the fields')
        setLoading(true)
      }
      try{
          const data = await API.Login({email,password});
          console.log('Login testing -->data: ', data)
          if(data === 'unauthorized'){handleError('invalid email and/or password')}
          if ((data && data !== 'undefined' && data !== 'unauthorized' )){
          
              let user = data?.data?.user
              if(user?._id) {handleSuccess('Login successful')
          
            setUserData(user)
        
              console.log('login userData: ', userData)
              localStorage.setItem('BOCUser', JSON.stringify(user))
              localStorage.setItem('authToken', user?.token);
              
              setUserData({...JSON.parse(localStorage.getItem('BOCUser'))})
              setLoading(false)
              //  if(userData){

                  updateAppData({
                  ...appData,
                  userData,
                  modalOpen: false,
                  modalView:''
                })
                handleSuccess('')
                window.location.assign(`/`)
                // window.location.assign(`/${user?._id}`)
          }else{handleError('error logging in')}
            }
        }catch(err){ 
          console.log('Login Error: ', err)
          handleError('email and password do not match')
        setLoading(false)}
  
    }

    const contStyle ={
        display:'flex',
        flexDirection:'column',
        width: '95%',
        justifyContent: 'center',
        alignItem:'center',
        margin:'auto',
        gap:'0.5rem',
        height:'fit-content',
        padding:'0.5rem',
        border:'1px solid #858585',
        borderRadius:'5px',
        backgroundColor:'rgba(255, 255,255, 0.5)',
        backdropFilter:'blur(5px)'

    }

    useEffect(()=>{
      

      // Retrieving the token
      const token = localStorage.getItem('authToken');

      // Removing the token on logout
      localStorage.removeItem('authToken');
    },[])
  return (

    <Box sx={contStyle}>
        <TextField label='email'
                id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  placeholder='email'
            onChange={(e)=>{
                setLoginState({
                    ...loginState,
                    email: e.target.value
                })
            }} />
        <TextField label='password' 
                id="password"
                  name="password"
                  type="password"
                  autoComplete="password"
                  placeholder='password'
                onChange={(e)=>{
                setLoginState({
                    ...loginState,
                    password: e.target.value
                })
            }}
              endAdornment={
            <InputAdornment position="end">
                <IconButton
                aria-label="toggle password visibility"
                onClick={handleShowClicK}
                onMouseDown={handleShowClicK}
                edge="end"
             
                >
                {!show? <VisibilityOff /> : <Visibility />}
                </IconButton>
            </InputAdornment>
                    }
            />
            <span sx={{display:'flex',gap:'0.5rem', alignSelf:'center',width:'100%'}}><CheckBox label=''/> <em>keep me logged in</em> </span>
        <Button variant='outlined'onClick={handleLogin} >Login</Button>
    </Box>
  )
}

export default Login