import React,{useState, useContext} from 'react'
import { AppContext } from "../context/AppProvider";
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Box, Button, FilledInput, IconButton, Input, InputAdornment, TextField } from '@mui/material'
import * as API from '../utils/APICalls'

const Signup = () => {
    const {currentPlace, setCurrentPlace, appData, 
        userData, setUserData,
        updateAppData,userLocation } = useContext(AppContext)
 const [ signupState, setSignupState ] = useState({

    })
    // const [ email , setEmail ] = useState('');
    // const [ password , setPassword ] = useState('');
    const [ show, setShow ] = useState(false)
    const [ loading , setLoading ] = useState(false)
    const [ picloading , setPicLoading ] = useState(false)
    const [ actionMessage, setActionMessage ] = useState({})

    const contStyle ={
        display:'flex',
        flexDirection:'column',
        width: '95%',
        justifyContent: 'center',
        margin:'auto',
        gap:'0.5rem',
        height:'fit-content',
        padding:'0.5rem',
        border:'1px solid #858585',
        borderRadius:'5px',
        backgroundColor:'rgba(255, 255,255, 0.5)',
        backdropFilter:'blur(5px)'

    }
    const fieldMap = ['first','last','preferred','email','phone','password']

  const handleShowClicK = (e) =>{
        e.preventDefault()
        setShow(!show)
  }
  
   const handleError = (err) =>
      setActionMessage({
        type:'error',
        message: err
    })
   
    const handleSuccess = (msg) =>
        setActionMessage({
           type:'success',
            message: msg
        })

//  =================
//  + SubmitHandler
//  =================

  const submitHandler = async (e) => {
    const { first, last, email, password, preferred, confirmPassword, phone,avatar } = signupState
    e.preventDefault()
    setPicLoading(true);
    setLoading(true)
    if (!first || !last || !email || !password || !confirmPassword || !phone) {
     console.log('signupState:', signupState)
        setActionMessage({
         type:'error',
         msg:'please fill all the feilds'
        })
      setPicLoading(false);
      return;
    }
    if (password !== confirmPassword) {
      setActionMessage({
         type:'error',
        msg:'passwords do not match'
    })
      setPicLoading(false);
      return;
    }
    
    try{
      // const  data  = await API.createUser(
      const  data = await API.signup(
        { first,
          last,
          preferred:preferred? preferred : first,
          email,
          phone,
          password,
          avatar:avatar,
          isOnline:true,
          linkage:true,
          appTheme:{
            //  BGcolorMain:colors['aura']?.bg,
            //   TXcolorMain:colors['aura']?.tx,
            //   TXcolorAccent:colors['aura']?.txAccent
          }
        });
        const { success, message ,user} = data;
      if (success) {
        await setUserData(user)
        await updateAppData({
          ...appData,
          userData
        })
        localStorage.setItem('authToken', user.token);
        handleSuccess(message);

        console.log(message);
        setTimeout(() => {
          // navigate("/");
        //   if( view === 'activate qr'){

        //     window.location.assign(`/assets/${qr_id}`)
        //   }else{

            window.location.assign(`/${user?._id}`)
        //   }
        }, 1000);
      } else {
        handleError(message);
        console.log(message);
      }
    } catch (err) {
        console.log('[Registration Error]: ',err)
        handleError( 'a registration error occurred')
        setPicLoading(false);
    }
  };

    return (

    <Box sx={contStyle}>
        <Box sx={{textAlign:'center', color: actionMessage?.type === 'error','red':'green'}}>

            {actionMessage?.msg}
        </Box>
        <TextField label='first'
             id="first"
            name="first"
            type="first"
            autoComplete="first"
            placeholder='first'
            onChange={(e)=>{
                setSignupState({
                    ...signupState,
                    first: e.target.value
                })
            }} />
        <TextField label='last'
             id="last"
            name="last"
            type="last"
            autoComplete="last"
            placeholder='last'
            onChange={(e)=>{
                setSignupState({
                    ...signupState,
                    last: e.target.value
                })
            }} />
        <TextField label='email'
             id="email"
            name="email"
            type="email"
            autoComplete="email"
            placeholder='email'
            onChange={(e)=>{
                setSignupState({
                    ...signupState,
                    email: e.target.value
                })
            }} />
        <TextField   
            label='phone'
             id="phone"
            name="phone"
            type="phone"
            autoComplete="phone"
            placeholder='phone'
            onChange={(e)=>{
                setSignupState({
                    ...signupState,
                    phone: e.target.value
                })
            }} />
        <FilledInput 
            variant='outlined'
            label='password' 
            id="password"
            name="password"
            type={!show?"password":'text'}
            autoComplete="password"
            placeholder='password'
            onChange={(e)=>{
                setSignupState({
                    ...signupState,
                    password: e.target.value
                })
            }}
            endAdornment={
            <InputAdornment position="end">
                <IconButton
                aria-label="toggle password visibility"
                onClick={handleShowClicK}
                onMouseDown={handleShowClicK}
                edge="end"
                >
            {!show? <VisibilityOff /> : <Visibility />}
                </IconButton>
            </InputAdornment>
            }
                />
        <FilledInput
            label='confirm password'
        type={!show?"password":'text'}
            onChange={(e)=>{
                    setSignupState({
                        ...signupState,
                        confirmPassword: e.target.value
                    })
                }}
                
            endAdornment={
            <InputAdornment position="end">
                <IconButton
                aria-label="toggle password visibility"
                onClick={handleShowClicK}
                onMouseDown={handleShowClicK}
                edge="end"
             
                >
                {!show? <VisibilityOff /> : <Visibility />}
                </IconButton>
            </InputAdornment>
                    }/>
        <Button variant='outlined' onClick={(e)=>{submitHandler(e)}}
        >Signup</Button>
    </Box>
  )

}

export default Signup