import { Box, Stack, Typography } from '@mui/material'
import React from 'react'

const LogoCopy = ({height,width}) => {
  return (
     <Box style={{
        display:'flex',
        
        gap:'0.5rem',
        color:'#FFF',
        // padding:'1rem',
        // position:'absolute',top:'3vh', right:'8%',
        zIndex:999,
    //   border:'1px solid red',
        alignItems:'center',
         width: width? width:'auto', 
        height: height? height:'auto'
        }}>
        <Box 
            component={'img'}
            src='/BOC_Logo_Text.svg' 
            style={{color:"#FFF", 
                // border:'1px solid red',
                alignSelf:'center',
                margin:'auto',
                width: width? width:'auto', 
                height: height? height:'auto'}}/>
            {/* <Stack> */}
                {/* <h1 style={{fontWeight:'bold'}}>Unifyd</h1> */}
                {/* <Typography variant='h4' style={{fontWeight:'bold'}}>Businesses of Color</Typography> */}
                {/* <h1 style={{fontWeight:'bold'}}>ForUs</h1> */}
                {/* <Typography variant='h6'style={{marginTop:'-0.5rem'}}>Unity in <em>OUR</em> Comm<u>unity</u></Typography> */}
            {/* </Stack> */}
    </Box>
  )
}

export default LogoCopy