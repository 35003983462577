import React ,{ useContext }from 'react'
import { AppContext } from "../context/AppProvider";
import GeoapifyAutocomplete from './GeoapifyAutocomplete'
import SwipeableDrawerComp from './SwipeableDrawer'
import { Box, Container } from '@mui/material'
import LogoCopy from './LogoCopy'
import NavSearch from './NavSearch';
import MapComponent from './MapComponent';

const navStyle = {
     position:'sticky',
     top:0,
     left:0,
    display: 'flex',
    width: '100%',
    // maxHeight:'80vh',
    // height:'8vh',
    justifyContent:'space-between',
    alignItems: 'center',
    zIndex:10,
    // padding:'0.5rem'
}
const inputStyle = {
    borderRadius:'8px',
     background: 'rgba( 0,0,0,0.3)',
    // background: 'rgba( 255,255,255,0.3)',
    backdropFilter: 'blur(5px)'
}
const NavBar = ({view}) => {
    const {currentPlace, setCurrentPlace, 
        showSearch, setShowSearch, isNewSearch, setIsNewSearch,
        places,isMenuOpen,isSearch } = useContext(AppContext)
  return (
     <nav style={navStyle}>      
        {/* {window.location.href.includes('/place/') &&  */}
        {/* <LogoCopy height='5rem' onClick={()=>{
            window.location.assign('/')
        }}/> */}
        <NavSearch />
       
         {/* } */}
         {/* {!isMenuOpen && <GeoapifyAutocomplete />} */}
             
        {/* <Box style={{zIndex:99, position:'absolute',top:'0.5rem', right:'0.5rem', alignSelf:'end'}}> */}
            {/* <LogoCopy /> */}
            {/* <SwipeableDrawerComp color={'#000'}/>
        </Box> */}
       
    </nav>
  )
}

export default NavBar