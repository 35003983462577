import React ,{useContext, useRef, useEffect} from 'react';
import { MapContainer, TileLayer, Marker, Popup,useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { AppContext } from "../context/AppProvider";
import L from 'leaflet';

// Fix for default marker icon paths
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const lon = -78.6382
const lat = 35.7796

  function milesToZoom(miles) {
  // Approximate zoom levels
  // 1 zoom level approx covers 69 miles at equator.
  return Math.round(12 - (miles / 69) * 12);
}

const greenIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const AutoOpenMarker = ({ position, popupText }) => {
  const markerRef = useRef(null);
  const map = useMap();

  useEffect(() => {
    if (markerRef.current) {
      markerRef.current.openPopup();
    }
  }, [map]);

  return (
    <Marker position={position} ref={markerRef} icon={greenIcon}>
      <Popup>{popupText}</Popup>
    </Marker>
  );
};
const MapComponent = (props) => {
  const {currentPlace, setCurrentPlace ,places, searchRadius,userLocation} = useContext(AppContext)
  const { height,width } = props
  const single= props?.single

  // console.log('[MapComponent] single:',single)
  let pins =[]= single? single :places 
  // console.log('userLocation?.latitude, userLocation?.longitude',userLocation?.latitude, userLocation?.longitude)
const zoomLevel = milesToZoom(searchRadius);

// function updateMapZoom(map, miles) {
//   const zoomLevel = milesToZoom(miles);
//   map.setZoom(zoomLevel);
// }

   return (
    // <MapContainer center={[lat, lon]} zoom={13} style={{ height: "100vh", width: "100%" }}>
   <>
    {pins?.length > 0 && <MapContainer 
      center={userLocation?.latitude && userLocation?.longitude ? [userLocation?.latitude, userLocation?.longitude]:
        [currentPlace.location.location.coordinates[1],currentPlace.location.location.coordinates[0]]} 
      zoom={zoomLevel} 
      style={{ height: height, width: width ,borderRadius:'15px'}}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
      />
       {/* <Marker position={[userLocation?.latitude, userLocation?.longitude]} icon={greenIcon}>
          <Popup sx={{backgroundColor:'red'}}>
          This is you
          </Popup>
        </Marker> */}

     {/* <AutoOpenMarker position={[userLocation?.latitude, userLocation?.longitude]} popupText="You are here" /> */}
      {pins?.length && pins?.map((place, index) =>{ 
        //  console.log('[place?.location?.location?.coordinates[1], place?.location?.location?.coordinates[0]]:',place?.location?.location?.coordinates[1], place?.location?.location?.coordinates[0])
        return(<Marker key={index} 
          style={{cursor:'pointer',zIndex:99999}}
          eventHandlers={{
              click: ()=>{
                setCurrentPlace(place)
                // window.location.assign(`/place/${place?._id}`)
                // console.log('pin click:',place)
                
              }
              }}
              position={[place?.location?.location?.coordinates[1], place?.location?.location?.coordinates[0]]}>
          <Popup  
          eventHandlers={{
              click: ()=>{
                setCurrentPlace(place)
                // window.location.assign(`/place/${place?._id}`)
                // console.log('pin click:',place)
                
              }
              }} 
          //  onClick={()=>{
          //   setCurrentPlace(place)
          //   console.log('pin click:',place)
          //       // window.location.assign(`/place/${place?._id}`)

          //     }}
              >
            {place?.name}
          </Popup>
        </Marker>
      )})}
    </MapContainer>}
   </>
  );       
};

export default MapComponent;