import Businesses from '../components/Businesses'
import React,{useContext, useRef, useEffect, useState} from 'react'
import LocationComponent from '../components/LocationComponent'
import { AppContext } from "../context/AppProvider";
import { Box, Container, Paper, Stack } from '@mui/material';
import GeoapifyAutocomplete from '../components/GeoapifyAutocomplete';
import LogoCopy from '../components/LogoCopy';

import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

SwiperCore.use([Navigation, Pagination, Autoplay]);

// const IndexComp = require('./index')
// const { default: Businesses } = require('./components/Businesses')

export default function Home(data){
     const {currentPlace, setCurrentPlace, userData,updateAppData, appData,
        setUserData, showSearch, setShowSearch, isNewSearch, 
        setIsNewSearch, places,isMenuOpen,isSearch } = useContext(AppContext)
         const [userCheck, setUserCheck] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const busSecRef = useRef(null)
    const page_heros = ['BOC_Vector_BG_02.svg','BOC_Vector_BG_03.svg','BOC_Vector_BG_01.svg','BOC_Vector_BG_04.svg','BOC_Vector_BG_05.svg','BOC_Vector_BG_06.svg','BOC_Vector_BG_07.svg','BOC_Vector_BG_08.svg']

      const scrollToBusinesses = () => {
        if (busSecRef.current && places) {
        busSecRef.current.scrollIntoView({ behavior: 'smooth' });
            setIsNewSearch(false)
        }
    };
    const getUserData = () =>{

        setUserData({...JSON.parse(localStorage.getItem('BOCUser'))})
    }
      const handleSlideChange = (index) => {
    setCurrentIndex(index);
  };

  useEffect(() => {
   scrollToBusinesses()
  }, [isNewSearch])



 useEffect(() => {
     if( !userCheck ){
      if(localStorage.getItem('BOCUser')){
        setUserData({...JSON.parse(localStorage.getItem('BOCUser'))})
         updateAppData({
           ...appData,
           userData
         })
        setUserCheck(true)
        console.log('localStorage:',JSON.parse(localStorage.getItem('BOCUser')) )
      }
     }else{
      setUserCheck(true)
      return}
    
    
  }, [userData, userCheck]);
  
    // console.log('home data:', places)
    
    return(
            <main style={{//position:'absolute',top:'0',color:'#000',
                fontFamily:'Poppins,sans-serif',
                // fontFamily:'Gilroy,sans-serif',
                display:'flex',
                flexDirection:'column',
                // gap:'0.5rem',
                minWidth:'350px',
                // height:'100%'
                minHeight:'100%'}}>
                
                {/* <Box sx={{
                    position:'sticky',
                    width:'70%', 
                    top:'0vh', 
                    right:'50vw',
                    zIndex:1000, 
                    minWidth:'325px',

                    transform: showSearch ?'translate(0%,20%)':'translate(0%,50%)'
                    }}>
                  
                    {!isMenuOpen && <GeoapifyAutocomplete />}
                </Box> */}

                <Box 
                    style={{
                    position:'relative',
                    // top:'0',
                    // left:'0',
                    // transform: showSearch ? 'translate(0%,-110%)' : 'translate(0%,-50%)',
                    borderRadius: '0 0 0px 0',
                    height: 'fit-content',
                    maxHeight: '500px',
                    width: '100%',
                    overflow:'hidden',
                     boxShadow: 'inset 0 -5px 5px rgba(0, 0, 0, 0.5)'
                }}>
                        {/* <em style={{position:'absolute',bottom:'0', right:'0.5rem',fontSize:'0.5rem',margin:'0 auto'}}>Photo by Edgar Castrejon on Unsplash</em> */}
                    {/* <Box
                        component={'img'}
                        src ="/BOC_Vector_BG_03.svg"
                        // src ="https://res.cloudinary.com/dzxzdsnha/image/upload/v1696117590/Rex_Entropy_Banner_lc4sox.png"
                        alt ="Business owners and the community" // width='100%' 
                        style={{
                            position:'relative',
                            // transform:showSearch ?'translate(0%,-120%)':'translate(0%,-50%)',
                             borderRadius:'0 0 80px 0',
                             width:'100vw'
                            // maxHeight:'50vh'
                        }}/> */}

                        <Swiper
                    //  className='swiper-container'
                    //  sx={{position:'fixed'}}
                     cssMode={true}
                        spaceBetween={0}
                        slidesPerView={1}
                        onSlideChange={handleSlideChange}
                        speed={1000}
                        // autoplay={true}
                        loop={true}
                        // modules={[Autoplay]}
                        autoplay={{
                            delay: 5000,
                            disableOnInteraction: false,
                        }}
                        // navigation={true}
                        // pagination={{ clickable: true }}
                        onSwiper={(swiper) => console.log(swiper)} // Optional: access Swiper instance
                    >
                        {page_heros?.map((image, index) => (
                        <SwiperSlide key={index}>
                            <img src={image} alt={`Image ${index + 1}`} style={{ width: '100%', height: '100%' }} />
                        </SwiperSlide>
                        ))}
                    </Swiper>

                </Box>

                {/* <img 
                // src ="./TacoBoard.jpg"
                src ="https://res.cloudinary.com/dzxzdsnha/image/upload/v1696117590/Rex_Entropy_Banner_lc4sox.png"
                 alt ="Taco Board" width='100%' 
                style={{position:'relative',transform:'translate(0%,-50%)',borderRadius:'0 0 40px 0',
                    
                }}/> */}
                    <Paper ref={busSecRef}
                    sx={{position:'sticky',top:'50%',height:'100%',
                        minHeight:'300px',
                        boxShadow:'0px -5px 10px 5px rgba( 0, 0, 0, 0.5)'}}>

             {places? <Businesses data={places} />:<h2 style={{textAlign:'center'}}>No businesses within established radius</h2>}
                    </Paper>
             
                {/* <a href="/places">
                    <button className="btn-primary">Places Page</button>
                </a> */}
                {/* <LocationComponent /> */}


            </main>
       
    )
}

