import React from 'react'
import { AddComment, Favorite, FavoriteBorder } from '@mui/icons-material';
import { Box } from '@mui/material';

const CommentFeedback = ({comment, user,showReply, setShowReply}) => {
    const c = comment 
  const handleLikeToggle = () =>{
      let list = c?.likes 
      if(list?.includes(user?._id)){
        //TODO Remove userId from comment likes list
      }else{
        //TODO Add userId from comment likes list
        list.push(user?._id)
        
      }

    }
  return (
    <Box style={{display: 'flex', alignItems:'center', cursor:'pointer', gap:'0.5rem'}}>
      <span onClick={handleLikeToggle}>  {c?.likes?.length}&nbsp;{!c?.likes?.includes(user?._id)?<FavoriteBorder/>:<Favorite sx={{color:'red'}}/>}</span>
      <AddComment  onClick={()=>{
        setShowReply(!showReply)
      }}/>
    </Box>
  )
}

export default CommentFeedback