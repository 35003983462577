
export const busTypeList = { 
"Restaurants": [
    "American (New)",
    "American (Traditional)",
    "Chinese",
    "Italian",
    "Mexican",
    "Japanese",
    "Indian",
    "French",
    "Thai",
    "Mediterranean",
    "Vietnamese",
    "Korean",
    "Pizza",
    "Seafood",
    "Steakhouses",
    "Sushi Bars",
    "Vegetarian",
    "Vegan",
    "Breakfast & Brunch",
    "Burgers"
  ],
  "Home Services": [
    "Electricians",
    "Plumbers",
    "Contractors",
    "Heating & Air Conditioning/HVAC",
    "Landscaping",
    "Roofing",
    "Home Cleaning",
    "Pest Control",
    "Painters",
    "Home Inspectors",
    "Movers",
    "Carpet Cleaning",
    "Handyman",
    "Tree Services",
    "Windows Installation",
    "Solar Installation",
    "Garage Door Services",
    "Security Systems",
    "Interior Design",
    "Home Organization"
  ],
  "Automotive": [
    "Auto Repair",
    "Car Dealers",
    "Oil Change Stations",
    "Tires",
    "Body Shops",
    "Car Wash",
    "Auto Detailing",
    "Auto Parts & Supplies",
    "Towing",
    "Windshield Installation & Repair",
    "Transmission Repair",
    "Smog Check Stations",
    "Motorcycle Repair",
    "RV Repair",
    "Battery Stores",
    "Car Stereo Installation",
    "Auto Glass Services",
    "Vehicle Wraps",
    "Roadside Assistance",
    "Car Rental"
  ],
  "Health & Medical": [
    "Doctors",
    "Dentists",
    "Chiropractors",
    "Physical Therapy",
    "Optometrists",
    "Urgent Care",
    "Hospitals",
    "Counseling & Mental Health",
    "Dermatologists",
    "Orthodontists",
    "Pediatricians",
    "Gynecologists",
    "Acupuncture",
    "Massage Therapy",
    "Nutritionists",
    "Pharmacy",
    "Home Health Care",
    "Medical Spas",
    "Speech Therapists",
    "Allergists"
  ],
  "Beauty & Spas": [
    "Hair Salons",
    "Nail Salons",
    "Day Spas",
    "Massage",
    "Waxing",
    "Skin Care",
    "Makeup Artists",
    "Hair Removal",
    "Eyelash Service",
    "Tanning",
    "Barbers",
    "Tattoo",
    "Piercing",
    "Saunas",
    "Cosmetics & Beauty Supply",
    "Permanent Makeup",
    "Hair Extensions",
    "Blow Dry/Out Services",
    "Hair Stylists",
    "Men's Hair Salons"
  ],
   "Financial Services": [
    "Banks & Credit Unions",
    "Financial Advising",
    "Insurance",
    "Mortgage Brokers",
    "Tax Services",
    "Investment Services",
    "Accounting",
    "Credit Counseling",
    "Financial Planning",
    "Wealth Management"
  ],
  "Legal Services": [
    "Lawyers",
    "Notaries",
    "Legal Document Services",
    "Mediation Services",
    "Patent Services",
    "Real Estate Law",
    "Family Law",
    "Criminal Law",
    "Corporate Law",
    "Immigration Law"
  ],
  "Education": [
    "Tutoring Centers",
    "Language Schools",
    "Driving Schools",
    "Music Lessons",
    "Dance Schools",
    "Test Preparation",
    "Vocational Schools",
    "Colleges & Universities",
    "Online Courses",
    "Special Education"
  ],
  "Recreation": [
    "Gyms",
    "Yoga Studios",
    "Martial Arts",
    "Parks",
    "Golf Courses",
    "Swimming Pools",
    "Sports Clubs",
    "Hiking",
    "Cycling",
    "Fitness Classes"
  ],
  "Web Services": [
    "Web Design",
    "SEO Services",
    "Digital Marketing",
    "Web Hosting",
    "E-commerce",
    "App Development",
    "Social Media Management",
    "Content Creation",
    "Graphic Design",
    "IT Support"
  ]
  ,
  "Engineering": {
    "Civil Engineering": [
      "Structural Engineering",
      "Environmental Engineering",
      "Geotechnical Engineering",
      "Transportation Engineering",
      "Water Resources Engineering",
      "Construction Engineering",
      "Municipal Engineering"
    ],
    "Mechanical Engineering": [
      "Aerospace Engineering",
      "Automotive Engineering",
      "Robotics Engineering",
      "Thermal Engineering",
      "Manufacturing Engineering"
    ],
    "Electrical Engineering": [
      "Power Engineering",
      "Telecommunications Engineering",
      "Control Engineering",
      "Electronics Engineering",
      "Microelectronics Engineering"
    ],
    "Chemical Engineering": [
      "Process Engineering",
      "Biochemical Engineering",
      "Materials Engineering",
      "Petroleum Engineering",
      "Pharmaceutical Engineering"
    ],
    "Computer Engineering": [
      "Software Engineering",
      "Hardware Engineering",
      "Network Engineering",
      "Cybersecurity Engineering",
      "Artificial Intelligence Engineering"
    ],
    "Industrial Engineering": [
      "Operations Research",
      "Systems Engineering",
      "Manufacturing Engineering",
      "Quality Engineering",
      "Supply Chain Engineering"
    ],
    "Environmental Engineering": [
      "Waste Management",
      "Water Treatment",
      "Air Quality Management",
      "Sustainability Engineering",
      "Renewable Energy Engineering"
    ],
    "Biomedical Engineering": [
      "Bioinformatics",
      "Biomechanics",
      "Biomaterials",
      "Medical Imaging",
      "Rehabilitation Engineering"
    ]
  },
  "Business Consulting": {
    "Strategy Consulting": [
      "Corporate Strategy",
      "Business Strategy",
      "Market Entry Strategy",
      "Growth Strategy",
      "Innovation Strategy",
      "Project Management"
    ],
    "Operations": [
      "Process Optimization",
      "Supply Chain Management",
      "Quality Management",
      "Lean Management",
      "Operational Excellence"
    ],
    "Financial": [
      "Financial Planning",
      "Risk Management",
      "Investment Consulting",
      "Tax Advisory",
      "Corporate Finance"
    ],
    "Human Resources": [
      "Talent Management",
      "Compensation & Benefits",
      "Employee Engagement",
      "Training & Development",
      "Diversity & Inclusion"
    ],
    "IT": [
      "Cybersecurity",
      "Data Analytics",
      "Cloud Computing",
      "Digital Transformation",
      "IT Strategy"
    ]
}
}