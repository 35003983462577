export const brands = ['facebook','instagram','linkedin','upwork', 'github',   'gitlab', 'twitter', 'bandcamp','soundcloud','spotify','youtube','vimeo',
        'airbnb','uber','lyft','stackoverflow','stackexchange','pinterest','skype','etsy','codepen', 'dropbox', 'googleplay','imdb','npm','meetup','itunes','cashApp','venmo','paypal','linktree','xbox','playstation','steam','snapchat','slack','kickstarter','patreon','stripe', 'discord','wordpress','tiktok','whatsapp',
        'windows','apple','shopify','twitch','tumblr','trello','unity','wikipedia'
    ]
export const busTypeList = { 
Restaurants: [
    "American (New)",
    "American (Traditional)",
    "Chinese",
    "Italian",
    "Mexican",
    "Japanese",
    "Indian",
    "French",
    "Thai",
    "Mediterranean",
    "Vietnamese",
    "Korean",
    "Pizza",
    "Seafood",
    "Steakhouses",
    "Sushi Bars",
    "Vegetarian",
    "Vegan",
    "Breakfast & Brunch",
    "Burgers"
  ],
  HomeServices: [
    "Electricians",
    "Plumbers",
    "Contractors",
    "Heating & Air Conditioning/HVAC",
    "Landscaping",
    "Roofing",
    "Home Cleaning",
    "Pest Control",
    "Painters",
    "Home Inspectors",
    "Movers",
    "Carpet Cleaning",
    "Handyman",
    "Tree Services",
    "Windows Installation",
    "Solar Installation",
    "Garage Door Services",
    "Security Systems",
    "Interior Design",
    "Home Organization"
  ],
  Automotive: [
    "Auto Repair",
    "Car Dealers",
    "Oil Change Stations",
    "Tires",
    "Body Shops",
    "Car Wash",
    "Auto Detailing",
    "Auto Parts & Supplies",
    "Towing",
    "Windshield Installation & Repair",
    "Transmission Repair",
    "Smog Check Stations",
    "Motorcycle Repair",
    "RV Repair",
    "Battery Stores",
    "Car Stereo Installation",
    "Auto Glass Services",
    "Vehicle Wraps",
    "Roadside Assistance",
    "Car Rental"
  ],
  HealthAndMedical: [
    "Doctors",
    "Dentists",
    "Chiropractors",
    "Physical Therapy",
    "Optometrists",
    "Urgent Care",
    "Hospitals",
    "Counseling & Mental Health",
    "Dermatologists",
    "Orthodontists",
    "Pediatricians",
    "Gynecologists",
    "Acupuncture",
    "Massage Therapy",
    "Nutritionists",
    "Pharmacy",
    "Home Health Care",
    "Medical Spas",
    "Speech Therapists",
    "Allergists"
  ],
  BeautyAndSpas: [
    "Hair Salons",
    "Nail Salons",
    "Day Spas",
    "Massage",
    "Waxing",
    "Skin Care",
    "Makeup Artists",
    "Hair Removal",
    "Eyelash Service",
    "Tanning",
    "Barbers",
    "Tattoo",
    "Piercing",
    "Saunas",
    "Cosmetics & Beauty Supply",
    "Permanent Makeup",
    "Hair Extensions",
    "Blow Dry/Out Services",
    "Hair Stylists",
    "Men's Hair Salons"
  ]
}