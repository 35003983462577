import { Button, IconButton, Stack } from '@mui/material';
import React, { useEffect, useState, useContext } from 'react';
import { AppContext } from "../context/AppProvider"; 
import * as API from '../utils/APICalls'
import SearchIcon from '@mui/icons-material/Search';

function LocationComponent({zip}) {
    const { updateAppData, appData, currentPlace, 
        setCurrentPlace, error, setError,
        searchRadius, setSearchRadius, isNewSearch, 
        isSearching, setIsSearching,
        setIsNewSearch,searchCategory,
        places, setPlaces,userLocation, setUserLocation} = useContext(AppContext)
  
    
    // const [error, setError] = useState(null);


    const getUserLocation = async () => {
      try{
        
          let geo_lat = window.geoplugin_latitude
            // let geo_lon = geoplugin_longitude()
          let geo_lon = window.geoplugin_longitude
            // setError('Geolocation is not supported by this browser.');
          console.log('[getUserLocation] geo_lat ,',geo_lat,',', geo_lon)
      
            setIsSearching(true)
          if (navigator.geolocation) {
           navigator.geolocation.getCurrentPosition(
               async (position) => {
                const { latitude, longitude } = position.coords;
                setUserLocation({ latitude, longitude });
                updateAppData({
                    ...appData,
                    userLocation,
                })
                // Send location to backend to find nearby businesses
                // const p = await API.fetchNearbyPlaces(latitude, longitude);
              
            },
            (error) => {
                setError('Error getting user location: ' + error.message);
            },
            {
                enableHighAccuracy: true,
                timeout: 10000,
                maximumAge: 0
            }
        );
        } else if(!navigator.geolocation ) {
          
              // let geo_lat = geoplugin_latitude()
              // let geo_lat = window.geoplugin_latitude()
              // let geo_lon = geoplugin_longitude()
              // let geo_lon = window.geoplugin_longitude()
              // setError('Geolocation is not supported by this browser.');
              console.log('[getUserLocation] geo_lat ,',geo_lat,',', geo_lon)
               setUserLocation({ latitude: geo_lat, longitude: geo_lon });
                updateAppData({
                    ...appData,
                    userLocation,
                })
              }
          if(userLocation?.latitude && userLocation?.longitude){
              const p = await API.fetchNearbyPlaces( userLocation?.latitude, userLocation?.longitude, searchRadius,zip,searchCategory);
              if(p){
                  console.log('[getUserLocation] places',p)
                  setPlaces(p)
                  setIsNewSearch(true)
                  updateAppData({
                      ...appData,
                      places,
                      isNewSearch
                  })
                  // setError('Place list length: '+ p?.length);
                      }else{
                          setPlaces([])
                          setError('No locations nearby. Please try explading rearch radius')        
                      }
              }
            
              setIsSearching(false)
      }catch(err){console.log(err)}
      };

    
    useEffect(()=>{
        getUserLocation()
    },[])

  return (
    <Stack sx={{color:'#000'}}>
      {/* <h1>Find Nearby Businesses</h1> */}
      <IconButton 
      disabled={isSearching}
      onClick={getUserLocation}
      sx={{color:'#000',height:'3rem',width:'3rem'}}
    //   icon={
    // }
    >
          <SearchIcon sx={{height:'2rem',width:'2rem'}}/>

      </IconButton>
      {/* {userLocation && (
        <div>
          <h2>User Location</h2>
          <p>Latitude: {userLocation.latitude}</p>
          <p>Longitude: {userLocation.longitude}</p>
        </div>
      )} */}
      {/* {error && <p style={{ color: 'red' }}>{error}</p>} */}
    </Stack>
  );
}

export default LocationComponent;