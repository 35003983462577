import React from 'react'

const Map = () => {
     const cords = [35.9457, -78.6433]
  return (
    <img src={`https://api.radar.io/maps/static?width=300&height=600&center=${cords[0]},${cords[1]}&zoom=15&style=radar-default-v1&scale=2&markers=color:0x000257|40.73430,-73.99110&publishableKey=prj_live_pk_...` }alt="Radar static map over New York City" />
            
  )
}

export default Map