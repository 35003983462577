import { Avatar, Box, Button, Card, Rating, Stack, Typography } from '@mui/material'
import React, {useState, useContext} from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import CommentFeedback from './CommentFeedback';
import ReplyIcon from '@mui/icons-material/Reply';
// import { AddComment } from '@mui/icons-material';
import * as helpers from '../utils/helpers'
import  AddComment from '../components/AddComment';
import { AppContext } from "../context/AppProvider";



const CommentCard = (props) => {
    
    const [showReply, setShowReply ] = useState(false)
    const {userData, currentPlace, setCurrentPlace, setShowSearch,updateAppData, appData} = useContext(AppContext)

    // const user = {
    //     _id:'64d1f9e7f1a4b2c2a1b0c1d3'
    // }
    const {index ,comment, place} = props
    const c = comment
    const p = place
    const cardStyles ={
        position:'relative',
        display:'flex',
        flexDirection: 'column',
        width:'100%',
        maxWidth:'400px',
        minWidth:'200px',
        gap:'0.5rem',
        padding:'0.5rem',
        border: '1px solid #656565',
        borderRadius:'5px'
    }
    // const date_info = c?.getDisplayDate()
    // console.log('date info:', date_info)
    return (
        <Stack sx={{alignItems:'center', gap:'0.5rem', minWidth:'370px',width:'100%'}}>
    <Card key={index} sx={cardStyles}>
          {/* <h2 className="rant">{c.rant ?'Rant! 🤬' :'Rave! 😍'}</h2> */}
          {p?.pic && <Box component='img'
           src={p?.pic}
           sx={{display:'flex',flexDirection:'column',height:'200px', width:'auto',borderRadius:'5px 5px 0 0'}}
           />}
            <Box sx={{display: 'flex', alignSelf:'end',//border:'1px solid blue',
                justifyContent:'space-between',gap:'0.5rem', width:'100%'}}>
                <Avatar
                sx={{ bgcolor:' #656565'}}
                alt={c?.author?.first_name + c?.author?.last_name?.slice(0, 1) + '.'}
                src={c?.author?.pic}
                />
            <Typography sx={{alignSelf:'end'}}>
                <Stack sx={{width:'80%',minWidth:'200px',//border:'1px solid red',

                }}>
                    {/* <b style={{alignSelf:'end'}}>Rating: {c.stars} </b> */}
                    <Rating style={{alignSelf:'start'}} name="half-rating" defaultValue={c.stars} precision={0.5} />
                    <p>{c?.content}</p>
                    <b>
                    <strong>- {helpers?.postName(c?.author)}</strong>
                    </b>
                </Stack>
            </Typography>
            </Box>
          
          {userData?._id?.toString() === c?.author?._id?.toString() && <form method="POST" action={`/places/${p.id}/comment/${c.id}?_method=DELETE`}>
              <Button type="submit" className="btn btn-danger" value="Delete Comment" >
                <DeleteIcon sx={{color: 'red', alignSelf:'end'}}/>
              </Button >
            </form>}

            <Box sx={{display:'flex', 
                flexDirection:'row',gap:'0.5rem',
                position:'relative',
                 left:'0.5rem', 
                // bottom:'0.25rem', 
                cursor:'pointer',
                alignItems: 'center',
                // border:'1px solid red'
                }}>
                <CommentFeedback comment={c} user={userData} showReply={showReply} setShowReply={setShowReply}/>
               
            </Box>
              <em style={{flex:1,textAlign:'right', width:'100%'}}>
                 {helpers?.getDisplayDate(c)}
                </em>

        {/* <AddCommentComponent /> */}
       {showReply && <Box sx={{minWidth:'200px',width:'80%',alignSelf:'end'}}>
             <AddComment view='reply' prevCom={c}/>
          </Box>}
        </Card>
        </Stack>
    
  )
}

export default CommentCard