import { Box, Button, Card, Chip, Link, Paper, Rating, Stack } from '@mui/material'
import React ,{useContext, useState} from 'react'
import LanguageIcon from '@mui/icons-material/Language';
import CategoryChip from './CategoryChip';
import { AppContext } from "../context/AppProvider";
import * as helpers from '../utils/helpers'
import * as globals from './globals'
import useWindowDimensions from "../hooks/windowDimensions";
import PhoneIcon from '@mui/icons-material/Phone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Brands from '@fortawesome/free-brands-svg-icons'
import { ArrowDropDown, ArrowDropUp} from '@mui/icons-material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar,  Controller } from 'swiper/modules';
import * as API from '../utils/APICalls'
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';




import { InfoOutlined, PinDrop } from '@mui/icons-material';
import { NavLink } from 'react-router-dom';
// import { haversineDistance } from '../utils/helpers';

const BusCard = (props) => {
    const { height, width } = useWindowDimensions();
    const [controlledSwiper, setControlledSwiper] = useState(null);
    const {currentPlace, setCurrentPlace, appData, 
            updateAppData,userLocation } = useContext(AppContext)

    const [ showHours, setShowHours ] = useState(true)
    const [isHovered, setIsHovered] = useState(false);
    const place = props.place
    let score = 0
    const images = helpers.compileImages(place)
        if(place?.comments?.length){
            score = Math.round(helpers?.ratingScore(place?.comments))
        }
    

    const cardStyles = {
        padding:"0.5rem",
        display:'flex',
        flexDirection: width <= 400 ? 'column':'row',
        gap:'0.5rem',
        width: '100%',
        minWidth: '300px',
        maxWidth: width <= 400 ? '350px':'400',
        alignItems: width <= 400 ? 'center':'start',
        // alignItems:'center',
        // height:'500px',
        img_box:{
            width: '100%',
            height: '100%',
            minHeight: width <= 400 ? '200px':'150px', 
            maxHeight: width <= 400 ? '100%':'250px', 
            minWidth: width <= 400 ? '100px':'150px', 
            maxWidth: width <= 400 ? '100%':'250px', 
            borderRadius:'4px',
            alignItems: width <= 400 ? 'center':'start',
            // alignContent: width <= 400 ? 'center':'start',

        },
        nameLink:{
            fontSize:'1rem', 
            color:'#000', 
            textDecoration: isHovered ? 'underline' : 'none',
            fontWeight:'bold',
            cursor: 'pointer'
        },
        img:{
            width: '100%',
             minHeight: width <= 400 ? '300px':'150px', 
             minWidth: width <= 400 ? '100%':'150px', 
            borderRadius:'3px'
        },
        stack:{
            // flex:1,
            gap:'0.5rem',
            textAlign: 'left',
            justifyContent:'start',
            fontSize: '0.8rem'
        }
    }
const status = helpers.showClosingTime(place?.hours)
// console.log('[status]', place?.showClosingTime)
    const statusColor = (pre) =>{
        let color = 'red'
        let p = pre?.toLowerCase()
        if( p === 'open' ){ color = 'green'}
        else if( p === 'opens' ||  p === 'closes' ){ color =  'orange'}
        return color
    } 

    const label = `<div style="text-align:center;">${place?.location?.city}, ${place?.location?.state} <br> <em style="font-size:0.75rem;">(${place?.location?.zip})</em></div>`;

    const CustomLabel = ({ }) => {
        return <span dangerouslySetInnerHTML={{ __html: label }} />;
        };
     const linkCheck = (l) =>{
        if(l.indexOf('http://') !== -1 || l.indexOf('https://') !== -1){
            return 'https://'+ l
        }else{ return l}
    }
    const linkIconCheck = (l) =>{
        // console.log('[linkIconCheck] brands?.some((k)=>l?.includes(k.toString))',globals.brands?.find((k)=>l?.includes(k?.toString())))
        let result = globals?.brands?.find((k)=>l?.includes(k?.toString()))
        if(result){
            return result?.charAt(0).toUpperCase() + result?.slice(1);
        }else{ return false}
    }
  return (
     <Card sx={cardStyles} //className="col-sm-6" 
     onClick={async()=>{
        try{   
            // setCurrentPlace(place)
            const res = await API.fetchPlaceById(place._id)
           setCurrentPlace(res)
           updateAppData({
                ...appData,
                currentPlace
           })

            console.log('place:', place)
        }catch(err){console.log('Error Fetching Place',err)}
            }}
        key={place?._id}>
        <Box sx={cardStyles?.img_box} className='col-med-2'
        >
            {/* <img src={images[0]} alt={place?.name}
            
            style={cardStyles.img}
            // sizes="(max-width: 1322px) 168px,(max-width: 1560px) 180px,258px"
            // minWidth='150px' 
            /> */}
             <Swiper className='mySwiper'
              loop={true}
                navigation={true}
                modules={[Navigation, Pagination, Controller]}
                controller={{ control: controlledSwiper }}
                    spaceBetween={0}
                    slidesPerView={1}
                    // onSlideChange={() => console.log('slide change')}
                    // onSwiper={(swiper) => console.log(swiper)}
                  >
                    {images?.map((image,i)=>{
                     return (<SwiperSlide   key={i}> 
                      <img src={image} 
                        style={{...cardStyles.img,
                          boxShadow:'inset 0px 0px 10px 5px rgba(0 ,0, 0, 0.5)'
                        }}
                      //  className="card-img-top" 
                        alt={place?.name}>

                    </img> 
                  </SwiperSlide>)
                  })
                }
                  </Swiper>
                    <span style={{cursor:'pointer',fontSize:'0.75rem',}}
                      onClick={()=>{
                        setShowHours(!showHours)
                      }}>
                      <u>
                        {showHours? <ArrowDropDown />:<ArrowDropUp />}
                        {showHours?'hide':'show'} hours</u>
                    </span>
                
                  {showHours && 
                    <Box sx={{display:'flex', flexDirection:'row',flexWrap:'wrap',height:'200px', 
                        // gap:'0.125rem',
                        width:'100%'}}>
                      {Object.keys(place?.hours)?.map((day,i)=>{
                          const amPmCheck = (time)=>{
                          if(time ==='Closed')return
                          return Number(time.split(':')[0])<= 12?'am':'pm'
                        }
                        let [close1, close2] = place?.hours[day]?.close?.split(':')
                        let closeTime = Number(close1)  > 12? Number(close1) - 12 : close1 
                        closeTime += close2 ?`:${close2?.toString()}`:''
                          return(
                            <span style={{ display:'flex',width:'100%',fontSize:'0.75rem', 
                                color: i % 2 ?'#757575':'#000'
                            }} >
                              <b style={{width:'2rem'}}>{day}:</b>
                              <span style={{textAlign:'right', width:'100%',}}>
                                {place?.hours[day]?.open} {amPmCheck(place?.hours[day]?.open)}- {closeTime} {amPmCheck(place?.hours[day]?.close)}
                             </span> 
                            </span>
                          )
                      })}
                    </Box>
                    }
        </Box>
<Box>

</Box>
        <Stack sx={cardStyles.stack}  className='col-med-4'>
            <b >
                <NavLink style={cardStyles.nameLink}
                // style={{}}
                     onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    // onClick={() => {
                    //     setCurrentPlace(place)
                    //     updateAppData({
                    //         ...appData,
                    //         currentPlace
                    //     })
                    // }}
                     onClick={async()=>{
                            try{   
                                // setCurrentPlace(place)
                             const res = await API.fetchPlaceById(place._id)
                            setCurrentPlace(res)
                            updateAppData({
                                    ...appData,
                                    currentPlace
                            })
                            window.location.assign(`/place/${place?._id}`)
                                console.log('place:', place)
                            }catch(err){console.log('Error Fetching Place',err)}
                        }}
                    // to={`/place/${place?._id}`}
                    >
                        {place?.name}
                </NavLink>
            </b>
              <span style={{alignSelf:'start'}}>
    
                    <b style={{color:statusColor(status?.preMessage)}}>{status?.preMessage}</b>&nbsp;{status?.message}
                </span>
           <span> <b>{Math.round(helpers.haversineDistance(place?.location?.location?.coordinates,[userLocation?.longitude, userLocation?.latitude]))}</b> mi. away</span>
            <Stack style={{display: 'flex', 
                alignItems:'center', gap:'0.25rem',alignContent:'start'}}>

                <Box style={{display:'flex',
                flexDirection: width <= 500 ? 'column':'row',
                    alignItems:'center',alignSelf:'start',gap:'0.25rem', margin:'0'}}>
                    <Rating style={{}} name="half-rating" defaultValue={5} precision={0.5} />
                    
                    <span>

                        <b>
                        { score ? score : '0.0'}
                        </b>
                            &nbsp;({place?.comments?.length} reviews)
                    </span>
                </Box>
              
            </Stack>
             <Box sx={{position:'relative',//bottom:'0px',
                    display:'flex',
                    // border:'1px solid red', 
                        minHeight:'1.5rem',
                        justifyContent:'space-between',
                        alignContent:'start',
                        alignItems:'start',
                        padding:'0.25rem',
                        gap:'0.5rem'
                        // width:'100%', 
                        // margin:'auto'
                        }}>

                    { place?.social_links?.map((link,j)=>{
                    
                        // let link = elemCheck(l)
                        // console.log('[profile] link:', link)
                       if(linkIconCheck(link)){
                            let iconName = linkIconCheck(link)
                            // console.log('iconName:', iconName)
                            return (
                                // <div
                                // style={{
                                //     // display:'flex',
                                //     color:'#000',  
                                //     width:'1.5rem',height:'1.5rem',
                                //     '&:hover': {background:'rgba(144, 254, 195,0.5)'},
                                //     // border:'1px solid blue', 
                                //     borderRadius:'3px',
                                //     alignContent:'center',
                                //     minWidth:'1rem',minHeight:'1rem'}} //key={i}
                                    
                                //     key={j}
                                //     > 
                                <Link
                                style={{
                                    // display:'flex',
                                    color:'#000',  
                                   cursor:'pointer',
                                    // border:'1px solid blue', 
                                    borderRadius:'3px',
                                    // alignContent:'center',
                                    fontSize:'1.5rem',
                                    // minWidth:'1rem',minHeight:'1.5rem'
                                }} //key={i}
                                  href={link}
                                    target='_blank'
                                    key={j}
                                    > 
                                    {/* {iconName} */}
                                    {globals?.brands['fa'+ iconName]}
                                    <FontAwesomeIcon icon={Brands['fa'+ iconName]} key={j} />
                                </Link>
                                // </div>
                           )
                        }
        

                })}
                
            </Box>
            <Stack sx={{display:'flex', flexDirection: width <= 400 ? 'column':'row', gap: '0.5rem'}}>
                <Link to={place?.phone} target='_blank' ><PhoneIcon />{place?.phone}</Link>
                <Link href={place?.website} target='_blank' ><LanguageIcon sx={{ color: '#757575' }} />website</Link>
                
            </Stack>
            <Stack>
                <span  target='_blank' >{place?.location?.address}</span>
                <span  target='_blank' >{place?.location?.city}, {place?.location?.state}</span>
                <span  target='_blank' >{place?.location?.zip}</span>
            </Stack>
            <Box sx={{display:'flex',flexDirection:'row',gap:'0.25rem'}}>
                {place?.categories?.map((c,i)=>
                    <CategoryChip key={i} category={c}/>
                    )}
            </Box>
            <Box sx={{display:'flex',flexDirection:'row',gap:'0.25rem'}}>
                {place?.sub_categories?.map((s,i)=>
                    <CategoryChip key={i} category={s} size='small'/>
                    )}
            </Box>
            
           {place?.about &&
            <Box sx={{display:'flex', gap:'0.125rem'}}>
               <InfoOutlined sx={{fontSize:'0.8rem', justifyContent:'center'}}/> 
                <p >
                    {place?.about}
                </p>
           </Box> }
           <Stack style={{width:'50%', gap:'0.5rem',alignSelf: width <= 400 ? 'start':'end',minWidth:'150px'}}>
            
                 <Chip
                    icon={<LanguageIcon sx={{ color: '#757575' }} />}
                    sx={{
                        height:'fit-content',
                        '& .MuiChip-label': {
                        display: 'block',
                        whiteSpace: 'normal',
                        },
                    }}
                    label={<CustomLabel label={label} />}
                    />
                    {/* <Box sx={{display: 'flex', }} > */}
                        {/* <Chip
                            label = {place?.location?.zip}
                            /> */}
                        <Chip
                            icon={<PinDrop />}
                            label = {`${place?.location?.location?.coordinates[0]}/${place?.location?.location?.coordinates[1]}`}
                            />
                    {/* </Box> */}
           </Stack>
                
            {/* <Chip sx={{display:'flex', flexDirection:'column',textAlign:'end', alignItems: 'center'}}>
                <span>
                <LanguageIcon sx={{color:'#757575'}}/>
                    <b> {place?.location?.city}, {place?.location?.state}</b>
                </span>
                {place?.location?.zip}
            </Chip> */}
        </Stack>
    </Card>
)
}

export default BusCard