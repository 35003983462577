import React, { useEffect, useState, useContext } from 'react';
import { Box, Button, Drawer, IconButton, ThemeProvider, createTheme } from '@mui/material';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import { AddToHomeScreen, AutoStories, Handshake, Login, SignpostOutlined } from '@mui/icons-material';
import AddReactionIcon from '@mui/icons-material/AddReaction';
import { NavLink } from 'react-router-dom';
import LogoCopy from './LogoCopy';
import LoginComp from './Login';
import SignupComp from './Signup';
import { AppContext } from "../context/AppProvider";
import * as API from '../utils/APICalls'

const SwipeableDrawerComp = (props) => {
  // const [isMenuOpen, setisMenuOpen] = useState(false);
  const { color } = props;
  // const [isMenuOpen, setIsMenuOpen] = useState(false);
   const {userData,setUserData,currentPlace, setCurrentPlace, places,isMenuOpen, setIsMenuOpen} = useContext(AppContext)
  const [ section, setSection ] = useState()

  const handleLogout  = () => {
      try{
        localStorage.removeItem('authToken');
        localStorage.removeItem('BOCUser');
        const res = API.updateUser({
            ...userData,
            isOnline: false,
          })
          setUserData()
        console.log('User logged out');
        // Optionally, redirect to the login page

      }catch(err){console.log('err',err)}
    }
 
const toggleDrawer = (open) => (event) => {
  if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    return;
  }
  setIsMenuOpen(open);
};

  const authIconMap = {
    'Login':{
      icon:<Login />,
      path:'/',
      func: ()=>{return}
    },
    'SignUp':{
      icon: <AddReactionIcon />,
      path:'/',
      func: ()=>{return}
    },
    'Logout':{
      icon: <LogoutIcon/>,
      path:'/' ,
      func: handleLogout
    }
  }
  const actionIconMap = {
    'Add a Business':{
      icon: <AddBusinessIcon />,
      path:'/places/new' ,
      func: ()=>{return}
    }, 
    'Get In Touch':{
      icon: <ConnectWithoutContactIcon/>,
      path:'/' ,
      func: ()=>{return}
    },
  }
  const infoIconMap = {
 'Our Story':{
      icon: <AutoStories />,
      path:'/' 
    }, 
    'Terms of Service':{
      icon: <Handshake />,
      path:'/' 
  
    }
  }


useEffect(()=>{

},[userData])

  const list = (anchor) => (
    <Box sx={{ width: '250px',backgroundColor:'rgba(66, 42, 11,0.7)',}} role="presentation">
      <Box sx={{opacity:'1', margin:'0.5rem auto'}}>

        {/* <LogoCopy height='5rem' /> */}
        <Box sx={{ 
                backgroundColor:'rgba(255,255,255,0.2)',
                
                // backgroundColor:'rgba(66, 42, 11,0.7)',
                position:'relative',
                left:'50%',
                top:'0.5px',                      
                    display:'flex',
                    flexDirection:'column',
                    width:'100%',padding:'0.5rem',
                    transform:'translate(-50%,10%)',
                    height: '70px',
                    // borderRadius:'10px',
                    // width:'fit-content',
                //   minWidth:'200px',
                //   maxWidth:'500px',
                    justifyContent:'center',
                    alignContent:'center',
                    alignItems:'center',
                    boxShadow: 'inset 0 0 3px rgba(80,45,22,0.8)',
                    // border: '2px solid rgb(80,45,22)',
                    // border: '2px solid #000',
                    overflow:'hidden'
                    }}>
            {/* <Box sx={{
                    // position:'absolute',
                // left:'50%',
                // top:'-25px',                      
                    display:'flex',
                    flexDirection:'column',
                    width:'100%',padding:'0.5rem',
                    // transform:'translate(-50%,10%)',
                    // backgroundColor:'rgba(255,255,255,0.5)',
                    // boxShadow:'inset 0px 0px 10px 3px rgba(126,171,111,1)',
                    // boxShadow:'inset 0px 0px 10px 5px rgba(229,187,58,0.7)',
                    // backgroundImage: 'linear-gradient( 40deg,hsl(31deg 29% 39%) 0%, hsl(27deg 59% 42%) 8%, hsl(20deg 80% 49%) 17%, hsl(24deg 78% 51%) 28%, hsl(30deg 67% 53%) 39%, hsl(35deg 67% 58%) 51%, hsl(41deg 79% 64%) 63%, hsl(43deg 91% 65%) 76%, hsl(32deg 100% 49%) 89%, hsl(0deg 100% 50%) 100%)',
                    // opacity:'0.75',
                    // height: '4rem',
                    height: '100px',
                    // borderRadius:'100%',
                    // width:'100px',
                //   minWidth:'200px',
                //   maxWidth:'500px',
                    justifyContent:'center',
                    alignContent:'center',
                    alignItems:'center',
                    // border: '2px solid #000',
                    }}> */}
        
                <LogoCopy sx={{margin:'auto',alignSelf:'center'}} width={'100px'} />
            {/* </Box> */}
          </Box>
      </Box>
           {userData && <Box sx={{margin:'auto', textAlign:'center'}}>

                { userData?.first ? `Welcome, ${userData?.first}!`:''}
            </Box>}
      <List>
        <Divider />
        {Object.keys(authIconMap).map((text, index) =>{ 
          if( (text === 'Login' || text === 'SignUp' )&& !userData?.token
              || (text === 'Logout' && userData)){
              return(
                <ListItem key={text} disablePadding sx={{display:'flex',
                  flexDirection:'column', 
                    justifyContent:'space-between',width:'100%'}}>
                  <ListItemButton sx={{width:'100%',display:'flex',justifyContent:'space-evenly'}}
                  onClick={(e)=>{
                      setSection(text)
                      authIconMap[text]?.func()
                  }}>
                    <ListItemIcon>
                      {authIconMap[text].icon}
                    </ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItemButton>
                  {section === 'Login'&& text === 'Login'&& <LoginComp/> }
                  {section === 'SignUp' && text  === 'SignUp'&& <SignupComp/> }
                </ListItem>
              )}else{return}
        }
        )}
      </List>
      <Divider />
      <List>
        {Object.keys(actionIconMap).map((text, index) => (
          <ListItem key={text} disablePadding>
            {/* <NavLink> */}
            {/* //  to={actionIconMap[text]?.path}> */}
            <ListItemButton>
              <ListItemIcon>
               {actionIconMap[text]?.icon}
              </ListItemIcon>

              <ListItemText primary={text} />
            </ListItemButton>
              {/* </NavLink> */}
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {Object.keys(infoIconMap).map((text, index) => (
          <ListItem key={text} disablePadding>
            {/* <NavLink> */}
            {/* //  to={actionIconMap[text]?.path}> */}
            <ListItemButton>
              <ListItemIcon>
               {infoIconMap[text]?.icon}
              </ListItemIcon>

              <ListItemText primary={text} />
            </ListItemButton>
              {/* </NavLink> */}
          </ListItem>
        ))}
      </List>
    </Box>
  );
    

useEffect(()=>{

},[isMenuOpen])
const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'primary' && {
              backgroundColor: '#202020',
              color: '#fff',
              // borderRadius:'10px 0px 0px 10px'
            }),
        }),
      },
    },
  },
});

const buttonStyle ={

}
  return (
    //   <ThemeProvider theme={theme}>

    //  <>
    //   <div style={{cursor:'pointer'}}
    //   onClick={()=>toggleDrawer(true)}>Open{isMenuOpen?'ed':''} drawer</div>
    //     <Drawer open={isMenuOpen} onClose={toggleDrawer(false)}>
    //     {/* {list} */}
    //   </Drawer>
    // </>
    // // </ThemeProvider>
    <Box sx={{ zIndex:99999}}>
      <IconButton
        sx={{ zIndex: 99999}}
        onClick={() => {
          setIsMenuOpen(!isMenuOpen);
        }}
      >
        <MenuIcon sx={{ fontSize: "2rem", color: color ? color : '#000', cursor: 'pointer' }} />

      </IconButton>
      <SwipeableDrawer
        // PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
      
        sx={{ //background:'#FFF',
          borderRadius:'10px 0px 0px 10px',}}
          anchor='right'
          open={isMenuOpen}
          onClose={() => setIsMenuOpen(false)}
          onOpen={() => setIsMenuOpen(true)}
        >
          {list('right')}
      </SwipeableDrawer>
    </Box>
  );
};

export default SwipeableDrawerComp;