import React, { useEffect, useState, useContext } from 'react'
import BusCard from './BusCard'
import { Box, Chip, Container, Divider, Modal, Paper, Stack } from '@mui/material'
import { Margin } from '@mui/icons-material'
import Map from './Map'
import { AppContext } from "../context/AppProvider";
import useWindowDimensions from "../hooks/windowDimensions";

import * as API from '../utils/APICalls'
import MapComponent from './MapComponent'
import Show from '../views/show'
// import MapComponent from './MapComponent'

const Businesses = (props) => {
    // const [ places, setPlaces ] = useState()
     const { height, width } = useWindowDimensions();
    const {currentPlace, setCurrentPlace ,places, 
        userLocation, 
        setPlaces} = useContext(AppContext)

    const filter_list = ['filter option','filter','filtery thing','filter stuff','other filter']
    
    // console.log('places:', places)
     let placesFormatted = places?.map((place,i) => {
            return(
            <BusCard place={place} key={i} onClick={()=>{
                setCurrentPlace(place)
                // console.log('place:', place)
            }}/>
            )
        })

    const feedStyles={
        width: '100%',
        minWidth:  width <= 400 ? '300px':'350px',
        maxWidth:  width <= 400 ? '350px':'700px',
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        gap:'0.5rem',
        margin:'1rem auto'  
    }

    const mapStyles={
        // width: '100%',
        // minWidth:'350px',
        // background: '#E0CB98',
        background: '#FFF',
        borderRadius: '5px', 
        // display:'flex',
        // flexDirection:'row',
        // justifyContent:'center',
        // gap:'0.5rem',
        // margin:'1rem auto'   
    }

    const ContStyles={
        padding:'0.5rem',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        gap:'0.5rem'  ,
         minHeight:'300px',
         maxWidth: '95vw' ,
        margin:'auto',
        //  boxShadow: '0 10px 5px rgba(0, 0, 0, 0.5)'
         //width <= 400 ? '350px':'400',
    }

    // useEffect(()=>{
    //     const fetchPlaces = async () =>{
    //         const p = await API.fetchPlaces()
    //         if(p){ 
    //             console.log('places',p)
    //             setPlaces(p) 
    //         }
    //         else{ return }
    //     }
    //     fetchPlaces() 
    // },[])

    useEffect(()=>{
       const generatePins = () =>{
        
       }
       generatePins()
    },[places])

    
  return (
     <Box sx={ContStyles}>
        <h1 style={{margin: 'auto',maxWidth:'500px',textWrap:'balance',textAlign:'center'}}>Promoting unity in communities of color</h1>
        <Divider />
     
    <Stack sx={{display:'flex', flexDirection:'row', justifyContent:'space-evenly',padding:'0.5rem'}}>

        {filter_list?.map((f,i)=>{
           return( <Chip 
                key={i}
                label={f}
                sx={{minWidth:'40px'}}
            />)
          })}
    </Stack>
        <Divider />
        <Box className='row'>
            {/* <Box className ="col-12 col-lg-6" > */}
                <Box className ="row" sx={feedStyles}>
                    {places? placesFormatted :'No businesses within est. radius'}
                </Box>
            {/* </Box> */}
                 {/* {currentPlace && width >1000 && <Box className="col-12 col-lg-6" sx={mapStyles}>
                 <Show view='home'/> */}
               
                {/* <Map /> */}
                {/* <Modal sx={{modalStyle}}>
                    
                    <MapComponent places={places}/>
                </Modal> */}
            {/* </Box>}b */}
        </Box>
    </Box>
  )
}

export default Businesses