import React, {useContext,useEffect, useState} from 'react'
import * as API from '../utils/APICalls'

import {useParams} from'react-router-dom'
import { AppContext } from "../context/AppProvider";

function Edit (data) {
    const {currentPlace, setCurrentPlace} = useContext(AppContext)
      const { id } = useParams();

     useEffect(()=>{
        const fetchPlace = async() =>{
        try{
            const p = await API.fetchPlaceById(id)
            if(p){
            setCurrentPlace(p)
            }else{ console.log('Non-existant');return}
        }catch(err){console.log(err)}
        }
        if(!currentPlace){

        fetchPlace()
    }else{return}
  },[currentPlace])
    return (
   
        <main >{/* className="col text-center"> */}
          <h1>Edit Place</h1>
                <form  method="POST" action={`/places/${currentPlace?._id}?_method=PUT`}>
                   <div className="row">
                        <div className ="form-group col-sm-6">
                            <label htmlFor ="name">Place Name</label>
                            <input 
                                className ="form-control" 
                                id ="name" 
                                name="name" 
                                defaultValue = {currentPlace?.name}
                                required/>
                            </div>
                        <div className ="form-group col-sm-6">
                            <label htmlFor ="pic">Place Picture</label>
                            <input 
                                className ="form-control"  
                                type = "text" //Change to url
                                id ="pic" 
                                name="pic" 
                                defaultValue = {currentPlace?.pic}
                                />
                        </div>
                        <div className ="form-group col-sm-6">
                            <label htmlFor ="city">Place City</label>
                            <input 
                                className ="form-control"  
                                id ="city" 
                                name="city" 
                                defaultValue = {data?.place?.location?.city}/>
                        </div>
                        <div className ="form-group col-sm-6">
                            <label htmlFor ="state">Place State</label>
                            <input 
                                className ="form-control"  
                                id ="state" 
                                name="state" 
                                defaultValue = {data?.place?.location?.state}
                                />
                        </div>
                    </div>
                    <div className ="form-group">
                        <label htmlFor ="cuisine">Place Cuisine</label>
                        <input 
                            className ="form-control" 
                            id ="cuisine" 
                            name="cuisine" 
                            defaultValue = {currentPlace?.cuisines}
                            required/>
                    </div>
                    <input 
                        className ="btn btn-primary" 
                        type = "submit" 
                        value = "Edit Places"/>
                </form>
            </main>
    
    )
}

export default Edit
